import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { ApiKeysRes, SelectedApiKeyData } from './api-keys.interface';

export enum SelfServiceApiKeysHttpActionTypes {
    LoadApiKeysData = '[SelfServiceApiKeys] Load ApiKeys Data',
    GetCustomerLookupServices = '[SelfServiceApiKeys] Get Customer Lookup Services',
    GetStoreCustomerInfoServices = '[SelfServiceApiKeys] Get Store Customer Info Services',
    GetBrandLookupServices = '[SelfServiceApiKeys] Get Brand Lookup Services',
    GetEmailBuilderServices = '[SelfServiceApiKeys] Get Email Builder Services',
    GetUtilityServices = '[SelfServiceApiKeys] Get Utility Services',
    GetSpecificApiKeyDetails = '[SelfServiceApiKeys] Get Specific Api Key Details',
    GenerateApiKey = '[SelfServiceApiKeys] Generate Api Key',
    SaveApiKey = '[SelfServiceApiKeys] Save Api Key',
    GetOlyticsServices = '[SelfServiceApiKeys] Get Olytics Services',
    GetSmsServices = '[SelfServiceApiKeys] Get Sms Services',
    GetAuthenticationServices = '[SelfServiceApiKeys] Get Authentication Services',
    GetFullApiKey = '[SelfServiceApiKeys] Get Full Api Key',
}

export enum SelfServiceApiKeysInnerActionTypes {
    LoadApiKeysDataSuccess = '[SelfServiceApiKeys] Load ApiKeys Data Success',
    LoadApiKeysDataDataError = '[SelfServiceApiKeys] Load ApiKeys Data Error',
    GetCustomerLookupServicesSuccess = '[SelfServiceApiKeys] Get Customer Lookup Services Success',
    GetCustomerLookupServicesError = '[SelfServiceApiKeys] Get Customer Lookup Services Error',
    GetStoreCustomerInfoServicesSuccess = '[SelfServiceApiKeys] Get Store Customer Info Services Success',
    GetStoreCustomerInfoServicesError = '[SelfServiceApiKeys] Get Store Customer Info Services Error',
    GetBrandLookupServicesSuccess = '[SelfServiceApiKeys] Get Brand Lookup Services Success',
    GetBrandLookupServicesError = '[SelfServiceApiKeys] Get Brand Lookup Services Error',
    GetEmailBuilderServicesSuccess = '[SelfServiceApiKeys] Get Email Builder Services Success',
    GetEmailBuilderServicesError = '[SelfServiceApiKeys] Get Email Builder Services Error',
    GetUtilityServicesSuccess = '[SelfServiceApiKeys] Get Utility Services Success',
    GetUtilityServicesError = '[SelfServiceApiKeys] Get Utility Services Error',
    GetSpecificApiKeyDetailsSuccess = '[SelfServiceApiKeys] Get Specific Api Key Details Success',
    GetSpecificApiKeyDetailsError = '[SelfServiceApiKeys] Get Specific Api Key Details Error',
    GenerateApiKeySuccess = '[SelfServiceApiKeys] Generate Api Key Success',
    GenerateApiKeyError = '[SelfServiceApiKeys] Generate Api Key Error',
    SaveApiKeySuccess = '[SelfServiceApiKeys] Save Api Key Success',
    SaveApiKeyError = '[SelfServiceApiKeys] Save Api Key Error',
    ClearGeneratedApiKey = '[SelfServiceApiKeys] Clear Generated Api Key',
    ClearApiKeyDetails = '[SelfServiceApiKeys] Clear Api Key Details',
    GetOlyticsServicesSuccess = '[SelfServiceApiKeys] Get Olytics Services Success',
    GetOlyticsServicesError = '[SelfServiceApiKeys] Get Olytics Services Error',
    GetSmsServicesSuccess = '[SelfServiceApiKeys] Get Sms Services Success',
    GetSmsServicesError = '[SelfServiceApiKeys] Get Sms Services Error',
    GetAuthenticationServicesSuccess = '[SelfServiceApiKeys] Get Authentication Services Success',
    GetAuthenticationServicesError = '[SelfServiceApiKeys] Get Authentication Services Error',
    ClearFullApiKey = '[SelfServiceApiKeys] Clear Full Api Key',
    GetFullApiKeySuccess = '[SelfServiceApiKeys] Get Full Api Key Success',
    GetFullApiKeyError = '[SelfServiceApiKeys] Get Full Api Key Error',
}

// ===== HTTP =====
export const LoadApiKeysData = createAction(
    SelfServiceApiKeysHttpActionTypes.LoadApiKeysData,
    props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number} }>()
);
export const GetCustomerLookupServices = createAction(SelfServiceApiKeysHttpActionTypes.GetCustomerLookupServices);
export const GetStoreCustomerInfoServices = createAction(SelfServiceApiKeysHttpActionTypes.GetStoreCustomerInfoServices);
export const GetBrandLookupServices = createAction(SelfServiceApiKeysHttpActionTypes.GetBrandLookupServices);
export const GetEmailBuilderServices = createAction(SelfServiceApiKeysHttpActionTypes.GetEmailBuilderServices);
export const GetUtilityServices = createAction(SelfServiceApiKeysHttpActionTypes.GetUtilityServices);
export const GenerateApiKey = createAction(SelfServiceApiKeysHttpActionTypes.GenerateApiKey);
export const GetSpecificApiKeyDetails = createAction(
    SelfServiceApiKeysHttpActionTypes.GetSpecificApiKeyDetails,
    props<{ payload: {apiKey: string} }>()
);
export const SaveApiKey = createAction(
    SelfServiceApiKeysHttpActionTypes.SaveApiKey,
    props<{ payload: SelectedApiKeyData }>()
);
export const GetOlyticsServices = createAction(SelfServiceApiKeysHttpActionTypes.GetOlyticsServices);
export const GetSmsServices = createAction(SelfServiceApiKeysHttpActionTypes.GetSmsServices);
export const GetAuthenticationServices = createAction(SelfServiceApiKeysHttpActionTypes.GetAuthenticationServices);
export const GetFullApiKey = createAction(SelfServiceApiKeysHttpActionTypes.GetFullApiKey, props<{ payload: string }>());
// ====== INNER ======
export const LoadApiKeysDataSuccess = createAction(
    SelfServiceApiKeysInnerActionTypes.LoadApiKeysDataSuccess,
    props<{ payload: ResponseWrapper<ApiKeysRes> }>()
);
export const LoadApiKeysDataError = createAction(
    SelfServiceApiKeysInnerActionTypes.LoadApiKeysDataDataError,
    props<{ payload: BasicResponse }>()
);
export const GetCustomerLookupServicesSuccess = createAction(
    SelfServiceApiKeysInnerActionTypes.GetCustomerLookupServicesSuccess,
    props<{ payload: ResponseWrapper<Record<string, string>> }>()
);
export const GetCustomerLookupServicesError = createAction(
    SelfServiceApiKeysInnerActionTypes.GetCustomerLookupServicesError,
    props<{ payload: BasicResponse }>()
);
export const GetStoreCustomerInfoServicesSuccess = createAction(
    SelfServiceApiKeysInnerActionTypes.GetStoreCustomerInfoServicesSuccess,
    props<{ payload: ResponseWrapper<Record<string, string>> }>()
);
export const GetStoreCustomerInfoServicesError = createAction(
    SelfServiceApiKeysInnerActionTypes.GetStoreCustomerInfoServicesError,
    props<{ payload: BasicResponse }>()
);
export const GetBrandLookupServicesSuccess = createAction(
    SelfServiceApiKeysInnerActionTypes.GetBrandLookupServicesSuccess,
    props<{ payload: ResponseWrapper<Record<string, string>> }>()
);
export const GetBrandLookupServicesError = createAction(
    SelfServiceApiKeysInnerActionTypes.GetBrandLookupServicesError,
    props<{ payload: BasicResponse }>()
);
export const GetEmailBuilderServicesSuccess = createAction(
    SelfServiceApiKeysInnerActionTypes.GetEmailBuilderServicesSuccess,
    props<{ payload: ResponseWrapper<Record<string, string>> }>()
);
export const GetEmailBuilderServicesError = createAction(
    SelfServiceApiKeysInnerActionTypes.GetEmailBuilderServicesError,
    props<{ payload: BasicResponse }>()
);
export const GetUtilityServicesSuccess = createAction(
    SelfServiceApiKeysInnerActionTypes.GetUtilityServicesSuccess,
    props<{ payload: ResponseWrapper<Record<string, string>> }>()
);
export const GetUtilityServicesError = createAction(
    SelfServiceApiKeysInnerActionTypes.GetUtilityServicesError,
    props<{ payload: BasicResponse }>()
);
export const GetSpecificApiKeyDetailsSuccess = createAction(
    SelfServiceApiKeysInnerActionTypes.GetSpecificApiKeyDetailsSuccess,
    props<{ payload: ResponseWrapper<SelectedApiKeyData> }>()
);
export const GetSpecificApiKeyDetailsError = createAction(
    SelfServiceApiKeysInnerActionTypes.GetSpecificApiKeyDetailsError,
    props<{ payload: BasicResponse }>()
);
export const GenerateApiKeySuccess = createAction(
    SelfServiceApiKeysInnerActionTypes.GenerateApiKeySuccess,
    props<{ payload: string }>()
);
export const GenerateApiKeyError = createAction(
    SelfServiceApiKeysInnerActionTypes.GenerateApiKeyError,
    props<{ payload: BasicResponse }>()
);
export const SaveApiKeySuccess = createAction(
    SelfServiceApiKeysInnerActionTypes.SaveApiKeySuccess,
    props<{ payload: ResponseWrapper<BasicResponse> }>()
);
export const SaveApiKeyError = createAction(
    SelfServiceApiKeysInnerActionTypes.SaveApiKeyError,
    props<{ payload: BasicResponse }>()
);
export const ClearGeneratedApiKey = createAction(SelfServiceApiKeysInnerActionTypes.ClearGeneratedApiKey);
export const ClearApiKeyDetails = createAction(SelfServiceApiKeysInnerActionTypes.ClearApiKeyDetails);
export const GetOlyticsServicesSuccess = createAction(
    SelfServiceApiKeysInnerActionTypes.GetOlyticsServicesSuccess,
    props<{ payload: ResponseWrapper<Record<string, string>> }>()
);
export const GetOlyticsServicesError = createAction(
    SelfServiceApiKeysInnerActionTypes.GetOlyticsServicesError,
    props<{ payload: BasicResponse }>()
);
export const GetSmsServicesSuccess = createAction(
    SelfServiceApiKeysInnerActionTypes.GetSmsServicesSuccess,
    props<{ payload: ResponseWrapper<Record<string, string>> }>()
);
export const GetSmsServicesError = createAction(
    SelfServiceApiKeysInnerActionTypes.GetSmsServicesError,
    props<{ payload: BasicResponse }>()
);
export const GetAuthenticationServicesSuccess = createAction(
    SelfServiceApiKeysInnerActionTypes.GetAuthenticationServicesSuccess,
    props<{ payload: ResponseWrapper<Record<string, string>> }>()
);
export const GetAuthenticationServicesError = createAction(
    SelfServiceApiKeysInnerActionTypes.GetAuthenticationServicesError,
    props<{ payload: BasicResponse }>()
);
export const GetFullApiKeySuccess = createAction(
    SelfServiceApiKeysInnerActionTypes.GetFullApiKeySuccess,
    props<{ payload: string }>()
);
export const GetFullApiKeyError = createAction(
    SelfServiceApiKeysInnerActionTypes.GetFullApiKeyError,
    props<{ payload: BasicResponse }>()
);
export const ClearFullApiKey = createAction(SelfServiceApiKeysInnerActionTypes.ClearFullApiKey);
