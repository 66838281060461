import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { DataLoaderTemplatesRes, DeleteModalData, DemographicData, DemographicsListRes, SelectedDemographicRes } from './demographics.interface';

export enum SelfServiceDemographicsHttpActionTypes {
    LoadDemographicsData = '[SelfServiceDemographics] Load Demographics Data',
    DownloadDemographicsList = '[SelfServiceDemographics] Download Demographics List',
    GetDemographicData = '[SelfServiceDemographics] Get Demographic Data',
    DeleteDemographic = '[SelfServiceDemographics] Delete Demographic',
    SaveDemographicData = '[SelfServiceDemographics] Save Demographic Data',
    GetDemographicDeleteModalData = '[SelfServiceDemographics] Get Demographic Delete Modal Data',
    GetDataLoaderDemographicTemplates = '[SelfServiceDemographics] Get Data Loader Demographic Templates',
    CheckDemographicName = '[SelfServiceDemographics] Check Demographic Name',
}

export enum SelfServiceDemographicsInnerActionTypes {
    LoadDemographicsDataSuccess = '[SelfServiceDemographics] Load Demographics Data Success',
    LoadDemographicsDataError = '[SelfServiceDemographics] Load Demographics Data Error',
    DownloadDemographicsListSuccess = '[SelfServiceDemographics] Download Demographics List Success',
    DownloadDemographicsListError = '[SelfServiceDemographics] Download Demographics List Error',
    GetDemographicDataSuccess = '[SelfServiceDemographics] Get Demographic Data Success',
    GetDemographicDataError = '[SelfServiceDemographics] Get Demographic Data Error',
    DeleteDemographicSuccess = '[SelfServiceDemographics] Delete Demographic Success',
    DeleteDemographicError = '[SelfServiceDemographics] Delete Demographic Error',
    ClearDemographicData = '[SelfServiceDemographics] Clear Demographic Data',
    SaveDemographicDataSuccess = '[SelfServiceDemographics] Save Demographic Data Success',
    SaveDemographicDataError = '[SelfServiceDemographics] Save Demographic Data Error',
    GetDemographicDeleteModalDataSuccess = '[SelfServiceDemographics] Get Demographic Delete Modal Data Success',
    GetDemographicDeleteModalDataError = '[SelfServiceDemographics] Get Demographic Delete Modal Data Error',
    GetDataLoaderDemographicTemplatesSuccess = '[SelfServiceDemographics] Get Data Loader Demographic Templates Success',
    GetDataLoaderDemographicTemplatesError = '[SelfServiceDemographics] Get Data Loader Demographic Templates Error',
    CheckDemographicNameSuccess = '[SelfServiceDemographics] Check Demographic Name Success',
    CheckDemographicNameError = '[SelfServiceDemographics] Check Demographic Name Error',
    ClearDemographicNameCheck = '[SelfServiceDemographics] Clear Demographic Name Check',
}

// ===== HTTP =====
export const LoadDemographicsData = createAction(
    SelfServiceDemographicsHttpActionTypes.LoadDemographicsData,
    props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number, singlePage?: boolean, includeAll?: boolean} }>()
);
export const DownloadDemographicsList = createAction(SelfServiceDemographicsHttpActionTypes.DownloadDemographicsList);
export const GetDemographicData = createAction(
    SelfServiceDemographicsHttpActionTypes.GetDemographicData,
    props<{ payload: {demographicId?: number} }>()
);
export const DeleteDemographic = createAction(
    SelfServiceDemographicsHttpActionTypes.DeleteDemographic,
    props<{ payload: {demographicId: number, demographicSearchPayload?: unknown} }>()
);
export const SaveDemographicData = createAction(
    SelfServiceDemographicsHttpActionTypes.SaveDemographicData,
    props<{ payload: DemographicData }>()
);
export const GetDemographicDeleteModalData = createAction(
    SelfServiceDemographicsHttpActionTypes.GetDemographicDeleteModalData,
    props<{ payload: {demographicId: number} }>()
);
export const GetDataLoaderDemographicTemplates = createAction(
    SelfServiceDemographicsHttpActionTypes.GetDataLoaderDemographicTemplates,
    props<{ payload: {demographicId: number} }>()
);
export const CheckDemographicName = createAction(
    SelfServiceDemographicsHttpActionTypes.CheckDemographicName,
    props<{ payload: {searchText: string} }>()
);
// ====== INNER ======
export const LoadDemographicsDataSuccess = createAction(
    SelfServiceDemographicsInnerActionTypes.LoadDemographicsDataSuccess,
    props<{ payload: ResponseWrapper<DemographicsListRes> }>()
);
export const LoadDemographicsDataError = createAction(
    SelfServiceDemographicsInnerActionTypes.LoadDemographicsDataError,
    props<{ payload: BasicResponse }>()
);
export const DownloadDemographicsListSuccess = createAction(
    SelfServiceDemographicsInnerActionTypes.DownloadDemographicsListSuccess
);
export const DownloadDemographicsListError = createAction(
    SelfServiceDemographicsInnerActionTypes.DownloadDemographicsListError,
    props<{ payload: BasicResponse }>()
);
export const GetDemographicDataSuccess = createAction(
    SelfServiceDemographicsInnerActionTypes.GetDemographicDataSuccess,
    props<{ payload: ResponseWrapper<SelectedDemographicRes> }>()
);
export const GetDemographicDataError = createAction(
    SelfServiceDemographicsInnerActionTypes.GetDemographicDataError,
    props<{ payload: BasicResponse }>()
);
export const DeleteDemographicSuccess = createAction(
    SelfServiceDemographicsInnerActionTypes.DeleteDemographicSuccess,
    props<{ payload: BasicResponse }>()
);
export const DeleteDemographicError = createAction(
    SelfServiceDemographicsInnerActionTypes.DeleteDemographicError,
    props<{ payload: BasicResponse }>()
);
export const SaveDemographicDataSuccess = createAction(
    SelfServiceDemographicsInnerActionTypes.SaveDemographicDataSuccess,
    props<{ payload: BasicResponse }>()
);
export const SaveDemographicDataError = createAction(
    SelfServiceDemographicsInnerActionTypes.SaveDemographicDataError,
    props<{ payload: BasicResponse }>()
);
export const ClearDemographicData = createAction(SelfServiceDemographicsInnerActionTypes.ClearDemographicData);
export const GetDemographicDeleteModalDataSuccess = createAction(
    SelfServiceDemographicsInnerActionTypes.GetDemographicDeleteModalDataSuccess,
    props<{ payload: ResponseWrapper<DeleteModalData> }>()
);
export const GetDemographicDeleteModalDataError = createAction(
    SelfServiceDemographicsInnerActionTypes.GetDemographicDeleteModalDataError,
    props<{ payload: BasicResponse }>()
);
export const GetDataLoaderDemographicTemplatesSuccess = createAction(
    SelfServiceDemographicsInnerActionTypes.GetDataLoaderDemographicTemplatesSuccess,
    props<{ payload: ResponseWrapper<DataLoaderTemplatesRes> }>()
);
export const GetDataLoaderDemographicTemplatesError = createAction(
    SelfServiceDemographicsInnerActionTypes.GetDataLoaderDemographicTemplatesError,
    props<{ payload: BasicResponse }>()
);
export const CheckDemographicNameSuccess = createAction(
    SelfServiceDemographicsInnerActionTypes.CheckDemographicNameSuccess,
    props<{ payload: BasicResponse }>()
);
export const CheckDemographicNameError = createAction(
    SelfServiceDemographicsInnerActionTypes.CheckDemographicNameError,
    props<{ payload: BasicResponse }>()
);
export const ClearDemographicNameCheck = createAction(SelfServiceDemographicsInnerActionTypes.ClearDemographicNameCheck);
