import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { DemographicRes, ProductClassesRes, ProductResponse, ProductsRes } from '@self-service/state/products/products.interface';

export enum SelfServiceProductsHttpActionTypes {
    LoadProductsData = '[SelfServiceProducts] Load Products Data',
    DownloadProductsData = '[SelfServiceProducts] Download Products Data',
    GetProductDetails = '[SelfServiceProducts] Get Product Details',
    GetAuditBureaus = '[SelfServiceProducts] Get Audit Bureaus',
    GetProductDefinitions = '[SelfServiceProducts] Get Product Definitions',
    GetPaymentOptions = '[SelfServiceProducts] Get Payment Options',
    GetPaidClasses = '[SelfServiceProducts] Get Paid Classes',
    GetPostalBarcodes = '[SelfServiceProducts] Get Postal Barcodes',
    GetProductCompanies = '[SelfServiceProducts] Get Product Companies',
    GetProductDemographics = '[SelfServiceProducts] Get Product Demographics',
    GetProductClasses = '[SelfServiceProducts] Get Product Classes',
    GetProductMergeData = '[SelfServiceProducts] Get Product Merge Data',
    GetProductRequestorRules = '[SelfServiceProducts] Get Product Requestor Rules',
    GetProductOtherDetails = '[SelfServiceProducts] Get Product Other Details',
    GetProductCreditGracing = '[SelfServiceProducts] Get Product Credit Gracing',
    GetProductAccounts = '[SelfServiceProducts] Get Product Accounts',
    GetProductAuditedDemographics = '[SelfServiceProducts] Get Product Audited Demographics',
    GetProductAssociatedDemographics = '[SelfServiceProducts] Get Product Associated Demographics',
    GetProductIssueCloseDemographics = '[SelfServiceProducts] Get Product Issue Close Demographics',
    GetProductPassAlongDemographics = '[SelfServiceProducts] Get Product Pass Along Demographics',
    GetProductAddressedDemographics = '[SelfServiceProducts] Get Product Addressed Demographics',
}

export enum SelfServiceProductsInnerActionTypes {
    LoadProductsDataSuccess = '[SelfServiceProducts] Load Products Data Success',
    LoadProductsDataDataError = '[SelfServiceProducts] Load Products Data Error',
    DownloadProductsDataSuccess = '[SelfServiceProducts] Download Products Data Success',
    DownloadProductsDataError = '[SelfServiceProducts] Download Products Data Error',
    GetProductDetailsSuccess = '[SelfServiceProducts] Get Product Details Success',
    GetProductDetailsError = '[SelfServiceProducts] Get Product Details Error',
    GetAuditBureausSuccess = '[SelfServiceProducts] Get Audit Bureaus Success',
    GetAuditBureausError = '[SelfServiceProducts] Get Audit Bureaus Error',
    GetProductDefinitionsSuccess = '[SelfServiceProducts] Get Product Definitions Success',
    GetProductDefinitionsError = '[SelfServiceProducts] Get Product Definitions Error',
    GetPaymentOptionsSuccess = '[SelfServiceProducts] Get Payment Options Success',
    GetPaymentOptionsError = '[SelfServiceProducts] Get Payment Options Error',
    GetPaidClassesSuccess = '[SelfServiceProducts] Get Paid Classes Success',
    GetPaidClassesError = '[SelfServiceProducts] Get Paid Classes Error',
    GetPostalBarcodesSuccess = '[SelfServiceProducts] Get Postal Barcodes Success',
    GetPostalBarcodesError = '[SelfServiceProducts] Get Postal Barcodes Error',
    GetProductCompaniesSuccess = '[SelfServiceProducts] Get Product Companies Success',
    GetProductCompaniesError = '[SelfServiceProducts] Get Product Companies Error',
    GetProductDemographicsSuccess = '[SelfServiceProducts] Get Product Demographics Success',
    GetProductDemographicsError = '[SelfServiceProducts] Get Product Demographics Error',
    GetProductClassesSuccess = '[SelfServiceProducts] Get Product Classes Success',
    GetProductClassesError = '[SelfServiceProducts] Get Product Classes Error',
    GetProductMergeDataSuccess = '[SelfServiceProducts] Get Product Merge Data Success',
    GetProductMergeDataError = '[SelfServiceProducts] Get Product Merge Data Error',
    GetProductRequestorRulesSuccess = '[SelfServiceProducts] Get Product Requestor Rules Success',
    GetProductRequestorRulesError = '[SelfServiceProducts] Get Product Requestor Rules Error',
    GetProductOtherDetailsSuccess = '[SelfServiceProducts] Get Product Other Details Success',
    GetProductOtherDetailsError = '[SelfServiceProducts] Get Product Other Details Error',
    GetProductCreditGracingSuccess = '[SelfServiceProducts] Get Product Credit Gracing Success',
    GetProductCreditGracingError = '[SelfServiceProducts] Get Product Credit Gracing Error',
    GetProductAccountsSuccess = '[SelfServiceProducts] Get Product Accounts Success',
    GetProductAccountsError = '[SelfServiceProducts] Get Product Accounts Error',
    GetProductAuditedDemographicsSuccess = '[SelfServiceProducts] Get Product Audited Demographics Success',
    GetProductAuditedDemographicsError = '[SelfServiceProducts] Get Product Audited Demographics Error',
    GetProductAssociatedDemographicsSuccess = '[SelfServiceProducts] Get Product Associated Demographics Success',
    GetProductAssociatedDemographicsError = '[SelfServiceProducts] Get Product Associated Demographics Error',
    GetProductIssueCloseDemographicsSuccess = '[SelfServiceProducts] Get Product Issue Close Demographics Success',
    GetProductIssueCloseDemographicsError = '[SelfServiceProducts] Get Product Issue Close Demographics Error',
    GetProductPassAlongDemographicsSuccess = '[SelfServiceProducts] Get Product Pass Along Demographics Success',
    GetProductPassAlongDemographicsError = '[SelfServiceProducts] Get Product Pass Along Demographics Error',
    GetProductAddressedDemographicsSuccess = '[SelfServiceProducts] Get Product Addressed Demographics Success',
    GetProductAddressedDemographicsError = '[SelfServiceProducts] Get Product Addressed Demographics Error',
}

// ===== HTTP =====
export const LoadProductsData = createAction(
    SelfServiceProductsHttpActionTypes.LoadProductsData,
    props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number, singlePage?: boolean, status?: number, productType?: number} }>()
);
export const DownloadProductsData = createAction(
    SelfServiceProductsHttpActionTypes.DownloadProductsData,
    props<{ payload: {searchText?: string, status?: number, productType?: number} }>()
);
export const GetProductDetails = createAction(
    SelfServiceProductsHttpActionTypes.GetProductDetails,
    props<{ payload: {productId: number} }>()
);
export const GetAuditBureaus = createAction(SelfServiceProductsHttpActionTypes.GetAuditBureaus);
export const GetProductDefinitions = createAction(
    SelfServiceProductsHttpActionTypes.GetProductDefinitions,
    props<{ payload: {productId: number} }>()
);
export const GetPaymentOptions = createAction(SelfServiceProductsHttpActionTypes.GetPaymentOptions);
export const GetPaidClasses = createAction(SelfServiceProductsHttpActionTypes.GetPaidClasses,
    props<{ payload: {productId: number} }>()
);
export const GetPostalBarcodes = createAction(SelfServiceProductsHttpActionTypes.GetPostalBarcodes);
export const GetProductCompanies = createAction(SelfServiceProductsHttpActionTypes.GetProductCompanies,
    props<{ payload: {productId: number} }>()
);
export const GetProductDemographics = createAction(SelfServiceProductsHttpActionTypes.GetProductDemographics,
    props<{ payload: {productId: number} }>()
);
export const GetProductClasses = createAction(SelfServiceProductsHttpActionTypes.GetProductClasses,
    props<{ payload: {productId: number, searchText?: string, numResults?: number, offset?: number, order?: string, sortBy?: string } }>()
);
export const GetProductMergeData = createAction(SelfServiceProductsHttpActionTypes.GetProductMergeData,
    props<{ payload: {productId: number} }>()
);
export const GetProductRequestorRules = createAction(SelfServiceProductsHttpActionTypes.GetProductRequestorRules,
    props<{ payload: {productId: number} }>()
);
export const GetProductOtherDetails = createAction(SelfServiceProductsHttpActionTypes.GetProductOtherDetails,
    props<{ payload: {productId: number} }>()
);
export const GetProductCreditGracing = createAction(SelfServiceProductsHttpActionTypes.GetProductCreditGracing,
    props<{ payload: {productId: number} }>()
);
export const GetProductAccounts = createAction(SelfServiceProductsHttpActionTypes.GetProductAccounts);
export const GetProductAuditedDemographics = createAction(SelfServiceProductsHttpActionTypes.GetProductAuditedDemographics,
    props<{ payload: {productId: number, searchText?: string, numResults?: number, offset?: number, order?: string, sortBy?: string } }>()
);
export const GetProductAssociatedDemographics = createAction(SelfServiceProductsHttpActionTypes.GetProductAssociatedDemographics,
    props<{ payload: {productId: number, searchText?: string, numResults?: number, offset?: number, order?: string, sortBy?: string } }>()
);
export const GetProductIssueCloseDemographics = createAction(SelfServiceProductsHttpActionTypes.GetProductIssueCloseDemographics,
    props<{ payload: {productId: number, searchText?: string, numResults?: number, offset?: number, order?: string, sortBy?: string } }>()
);
export const GetProductPassAlongDemographics = createAction(SelfServiceProductsHttpActionTypes.GetProductPassAlongDemographics,
    props<{ payload: {productId: number, searchText?: string, numResults?: number, offset?: number, order?: string, sortBy?: string } }>()
);
export const GetProductAddressedDemographics = createAction(SelfServiceProductsHttpActionTypes.GetProductAddressedDemographics,
    props<{ payload: {productId: number, searchText?: string, numResults?: number, offset?: number, order?: string, sortBy?: string } }>()
);
// ====== INNER ======
export const LoadProductsDataSuccess = createAction(
    SelfServiceProductsInnerActionTypes.LoadProductsDataSuccess,
    props<{ payload: ResponseWrapper<ProductsRes> }>()
);
export const LoadProductsDataError = createAction(
    SelfServiceProductsInnerActionTypes.LoadProductsDataDataError,
    props<{ payload: BasicResponse }>()
);
export const DownloadProductsDataSuccess = createAction(SelfServiceProductsInnerActionTypes.DownloadProductsDataSuccess);
export const DownloadProductsDataError = createAction(
    SelfServiceProductsInnerActionTypes.DownloadProductsDataError,
    props<{ payload: BasicResponse }>()
);
export const GetProductDetailsSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductDetailsSuccess,
    props<{ payload: ProductResponse }>()
);
export const GetProductDetailsError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductDetailsError,
    props<{ payload: BasicResponse }>()
);
export const GetAuditBureausSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetAuditBureausSuccess,
    props<{ payload: Array<string> }>()
);
export const GetAuditBureausError = createAction(
    SelfServiceProductsInnerActionTypes.GetAuditBureausError,
    props<{ payload: BasicResponse }>()
);
export const GetProductDefinitionsSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductDefinitionsSuccess,
    props<{ payload: ResponseWrapper<ProductResponse> }>()
);
export const GetProductDefinitionsError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductDefinitionsError,
    props<{ payload: BasicResponse }>()
);
export const GetPaymentOptionsSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetPaymentOptionsSuccess,
    props<{ payload: Array<{code: number, name: string}> }>()
);
export const GetPaymentOptionsError = createAction(
    SelfServiceProductsInnerActionTypes.GetPaymentOptionsError,
    props<{ payload: BasicResponse }>()
);
export const GetPaidClassesSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetPaidClassesSuccess,
    props<{ payload: Array<{code: number, name: string}> }>()
);
export const GetPaidClassesError = createAction(
    SelfServiceProductsInnerActionTypes.GetPaidClassesError,
    props<{ payload: BasicResponse }>()
);
export const GetPostalBarcodesSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetPostalBarcodesSuccess,
    props<{ payload: Array<{code: number, name: string}> }>()
);
export const GetPostalBarcodesError = createAction(
    SelfServiceProductsInnerActionTypes.GetPostalBarcodesError,
    props<{ payload: BasicResponse }>()
);
export const GetProductCompaniesSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductCompaniesSuccess,
    props<{ payload: Array<{name: string, value: string}> }>()
);
export const GetProductCompaniesError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductCompaniesError,
    props<{ payload: BasicResponse }>()
);
export const GetProductDemographicsSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductDemographicsSuccess,
    props<{ payload: ResponseWrapper<ProductResponse> }>()
);
export const GetProductDemographicsError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductDemographicsError,
    props<{ payload: BasicResponse }>()
);
export const GetProductClassesSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductClassesSuccess,
    props<{ payload: ResponseWrapper<ProductClassesRes> }>()
);
export const GetProductClassesError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductClassesError,
    props<{ payload: BasicResponse }>()
);
export const GetProductMergeDataSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductMergeDataSuccess,
    props<{ payload: ResponseWrapper<ProductResponse> }>()
);
export const GetProductMergeDataError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductMergeDataError,
    props<{ payload: BasicResponse }>()
);
export const GetProductRequestorRulesSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductRequestorRulesSuccess,
    props<{ payload: ResponseWrapper<ProductResponse> }>()
);
export const GetProductRequestorRulesError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductRequestorRulesError,
    props<{ payload: BasicResponse }>()
);
export const GetProductOtherDetailsSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductOtherDetailsSuccess,
    props<{ payload: ResponseWrapper<ProductResponse> }>()
);
export const GetProductOtherDetailsError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductOtherDetailsError,
    props<{ payload: BasicResponse }>()
);
export const GetProductCreditGracingSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductCreditGracingSuccess,
    props<{ payload: ResponseWrapper<ProductResponse> }>()
);
export const GetProductCreditGracingError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductCreditGracingError,
    props<{ payload: BasicResponse }>()
);
export const GetProductAccountsSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductAccountsSuccess,
    props<{ payload: Array<{accountId: number, accountName: string, accountType: string}>}>()
);
export const GetProductAccountsError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductAccountsError,
    props<{ payload: BasicResponse }>()
);
export const GetProductAuditedDemographicsSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductAuditedDemographicsSuccess,
    props<{ payload: ResponseWrapper<DemographicRes> }>()
);
export const GetProductAuditedDemographicsError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductAuditedDemographicsError,
    props<{ payload: BasicResponse }>()
);
export const GetProductAssociatedDemographicsSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductAssociatedDemographicsSuccess,
    props<{ payload: ResponseWrapper<DemographicRes> }>()
);
export const GetProductAssociatedDemographicsError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductAssociatedDemographicsError,
    props<{ payload: BasicResponse }>()
);
export const GetProductIssueCloseDemographicsSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductIssueCloseDemographicsSuccess,
    props<{ payload: ResponseWrapper<DemographicRes> }>()
);
export const GetProductIssueCloseDemographicsError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductIssueCloseDemographicsError,
    props<{ payload: BasicResponse }>()
);
export const GetProductPassAlongDemographicsSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductPassAlongDemographicsSuccess,
    props<{ payload: ResponseWrapper<DemographicRes> }>()
);
export const GetProductPassAlongDemographicsError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductPassAlongDemographicsError,
    props<{ payload: BasicResponse }>()
);
export const GetProductAddressedDemographicsSuccess = createAction(
    SelfServiceProductsInnerActionTypes.GetProductAddressedDemographicsSuccess,
    props<{ payload: ResponseWrapper<DemographicRes> }>()
);
export const GetProductAddressedDemographicsError = createAction(
    SelfServiceProductsInnerActionTypes.GetProductAddressedDemographicsError,
    props<{ payload: BasicResponse }>()
);
