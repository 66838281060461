import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { DemographicsListRes, SaveConsolidatedDemographicPayload, SelectedDemographicRes } from './consolidated-demographics.interface';

export enum SelfServiceConsolidatedDemographicsHttpActionTypes {
    LoadConsolidatedDemographicsData = '[SelfServiceConsolidatedDemographics] Load Consolidated Demographics Data',
    DownloadConsolidatedDemographicsList = '[SelfServiceConsolidatedDemographics] Download Consolidated Demographics List',
    GetConsolidatedDemographicData = '[SelfServiceConsolidatedDemographics] Get Consolidated Demographic Data',
    SaveConsolidatedDemographic = '[SelfServiceConsolidatedDemographics] Save Consolidated Demographic',
    DownloadAllMapping = '[SelfServiceConsolidatedDemographics] Download All Mapping',
    UploadMapping = '[SelfServiceConsolidatedDemographics] Upload Mapping',
}

export enum SelfServiceConsolidatedDemographicsInnerActionTypes {
    LoadConsolidatedDemographicsDataSuccess = '[SelfServiceConsolidatedDemographics] Load Consolidated Demographics Data Success',
    LoadConsolidatedDemographicsDataError = '[SelfServiceConsolidatedDemographics] Load Consolidated Demographics Data Error',
    DownloadConsolidatedDemographicsListSuccess = '[SelfServiceConsolidatedDemographics] Download Consolidated Demographics List Success',
    DownloadConsolidatedDemographicsListError = '[SelfServiceConsolidatedDemographics] Download Consolidated Demographics List Error',
    GetConsolidatedDemographicDataSuccess = '[SelfServiceConsolidatedDemographics] Get Consolidated Demographic Data Success',
    GetConsolidatedDemographicDataError = '[SelfServiceConsolidatedDemographics] Get Consolidated Demographic Data Error',
    ClearConsolidatedDemographicData = '[SelfServiceConsolidatedDemographics] Clear Consolidated Demographic Data',
    SaveConsolidatedDemographicSuccess = '[SelfServiceConsolidatedDemographics] Save Consolidated Demographic Success',
    SaveConsolidatedDemographicError = '[SelfServiceConsolidatedDemographics] Save Consolidated Demographic Error',
    DownloadAllMappingSuccess = '[SelfServiceConsolidatedDemographics] Download All Mapping Success',
    DownloadAllMappingError = '[SelfServiceConsolidatedDemographics] Download All Mapping Error',
    UploadMappingSuccess = '[SelfServiceConsolidatedDemographics] Upload Mapping Success',
    UploadMappingError = '[SelfServiceConsolidatedDemographics] Upload Mapping Error',
}

// ===== HTTP =====
export const LoadConsolidatedDemographicsData = createAction(
    SelfServiceConsolidatedDemographicsHttpActionTypes.LoadConsolidatedDemographicsData,
    props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number, singlePage?: boolean} }>()
);
export const DownloadConsolidatedDemographicsList = createAction(SelfServiceConsolidatedDemographicsHttpActionTypes.DownloadConsolidatedDemographicsList);
export const GetConsolidatedDemographicData = createAction(
    SelfServiceConsolidatedDemographicsHttpActionTypes.GetConsolidatedDemographicData,
    props<{ payload: {demographicId?: number} }>()
);
export const SaveConsolidatedDemographic = createAction(
    SelfServiceConsolidatedDemographicsHttpActionTypes.SaveConsolidatedDemographic,
    props<{ payload: SaveConsolidatedDemographicPayload }>()
);
export const DownloadAllMapping = createAction(SelfServiceConsolidatedDemographicsHttpActionTypes.DownloadAllMapping);
export const UploadMapping = createAction(
    SelfServiceConsolidatedDemographicsHttpActionTypes.UploadMapping,
    props<{ payload: { file: File, overwrite: boolean } }>()
);
    
// ====== INNER ======
export const LoadConsolidatedDemographicsDataSuccess = createAction(
    SelfServiceConsolidatedDemographicsInnerActionTypes.LoadConsolidatedDemographicsDataSuccess,
    props<{ payload: ResponseWrapper<DemographicsListRes> }>()
);
export const LoadConsolidatedDemographicsDataError = createAction(
    SelfServiceConsolidatedDemographicsInnerActionTypes.LoadConsolidatedDemographicsDataError,
    props<{ payload: BasicResponse }>()
);
export const DownloadConsolidatedDemographicsListSuccess = createAction(
    SelfServiceConsolidatedDemographicsInnerActionTypes.DownloadConsolidatedDemographicsListSuccess
);
export const DownloadConsolidatedDemographicsListError = createAction(
    SelfServiceConsolidatedDemographicsInnerActionTypes.DownloadConsolidatedDemographicsListError,
    props<{ payload: BasicResponse }>()
);
export const GetConsolidatedDemographicDataSuccess = createAction(
    SelfServiceConsolidatedDemographicsInnerActionTypes.GetConsolidatedDemographicDataSuccess,
    props<{ payload: ResponseWrapper<SelectedDemographicRes> }>()
);
export const GetConsolidatedDemographicDataError = createAction(
    SelfServiceConsolidatedDemographicsInnerActionTypes.GetConsolidatedDemographicDataError,
    props<{ payload: BasicResponse }>()
);
export const ClearConsolidatedDemographicData = createAction(SelfServiceConsolidatedDemographicsInnerActionTypes.ClearConsolidatedDemographicData);
export const SaveConsolidatedDemographicSuccess = createAction(
    SelfServiceConsolidatedDemographicsInnerActionTypes.SaveConsolidatedDemographicSuccess,
    props<{ payload: BasicResponse }>()
);
export const SaveConsolidatedDemographicError = createAction(
    SelfServiceConsolidatedDemographicsInnerActionTypes.SaveConsolidatedDemographicError,
    props<{ payload: BasicResponse }>()
);
