import { ResponseWrapper } from 'common';

export enum LogoManagementTabs {
    DEFAULT = 'default',
    INVOICES = 'invoices',
    DEPLOYMENT_TYPES = 'deployment-types',
    PREFERENCE_PAGES = 'preference-pages'
}

export const logosInitialState: LogosState = {
    logosData: {
        loading: false,
        data: {
            [LogoManagementTabs.DEFAULT]: null,
            [LogoManagementTabs.INVOICES]: null,
            [LogoManagementTabs.DEPLOYMENT_TYPES]: null,
            [LogoManagementTabs.PREFERENCE_PAGES]: null
        }
    },
    options: {
        loading: false,
        data: {
            [LogoManagementTabs.DEPLOYMENT_TYPES]: [],
            [LogoManagementTabs.INVOICES]: [],
            [LogoManagementTabs.PREFERENCE_PAGES]: []
        }
    },
    selectedTab: LogoManagementTabs.DEFAULT,
    selectedOption: 0
};

export interface LogosState {
    logosData: ResponseWrapper<LogosData>;
    options: ResponseWrapper<{
        [LogoManagementTabs.DEPLOYMENT_TYPES]?: LogoOptionBase[]
        [LogoManagementTabs.INVOICES]?: LogoOptionBase[]
        [LogoManagementTabs.PREFERENCE_PAGES]?: LogoOptionBase[]
    }>;
    selectedTab: LogoManagementTabs;
    selectedOption: number;
}

export interface LogoOptionBase {
    code: string;
    name: string;
}

export interface LogoResponse {
    url: string;
    message: string;
}

export interface LogosData {
    [LogoManagementTabs.DEFAULT]: LogoResponse;
    [LogoManagementTabs.INVOICES]: LogoResponse;
    [LogoManagementTabs.DEPLOYMENT_TYPES]: LogoResponse;
    [LogoManagementTabs.PREFERENCE_PAGES]: LogoResponse;
}
