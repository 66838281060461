import { createAction, props } from '@ngrx/store';
import { BasicResponse } from 'common';
import { SkuManagementData, SkuManagementItem } from './sku-management.interface';

export enum SelfServiceSkuManagementHttpActionTypes {
    GetSkusData = '[SelfServiceSkuManagement] Get Skus Data',
    GetSelectedSkuData = '[SelfServiceSkuManagement] Get Selected Sku Data',
}

export enum SelfServiceSkuManagementInnerActionTypes {
    GetSkusDataSuccess = '[SelfServiceSkuManagement] Get Skus Data Success',
    GetSkusDataError = '[SelfServiceSkuManagement] Get Skus Data Error',
    GetSelectedSkuDataSuccess = '[SelfServiceSkuManagement] Get Selected Sku Data Success',
    GetSelectedSkuDataError = '[SelfServiceSkuManagement] Get Selected Sku Data Error',
}

// ===== HTTP =====
export const GetSkusData = createAction(SelfServiceSkuManagementHttpActionTypes.GetSkusData, props<{ payload: { searchText: string, sortBy: string, order: string, numResults: number, typeId: string, offset: number } }>());
export const GetSelectedSkuData = createAction(SelfServiceSkuManagementHttpActionTypes.GetSelectedSkuData, props<{ payload: { skuId: number } }>());

// ====== INNER ======
export const GetSkusDataSuccess = createAction(
    SelfServiceSkuManagementInnerActionTypes.GetSkusDataSuccess,
    props<{ payload: SkuManagementData }>()
);
export const GetSkusDataError = createAction(
    SelfServiceSkuManagementInnerActionTypes.GetSkusDataError,
    props<{ payload: BasicResponse }>()
);
export const GetSelectedSkuDataSuccess = createAction(
    SelfServiceSkuManagementInnerActionTypes.GetSelectedSkuDataSuccess,
    props<{ payload: SkuManagementItem }>()
);
export const GetSelectedSkuDataError = createAction(
    SelfServiceSkuManagementInnerActionTypes.GetSelectedSkuDataError,
    props<{ payload: BasicResponse }>()
);