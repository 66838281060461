import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { SelfServiceProductsRes } from './interfaces';

export enum CustomerDataPlatformInnerActionTypes {
    SyncEnvironmentSuccess = '[SelfService] Sync Environment Success',
    SyncEnvironmentError = '[SelfService] Sync Environment Error',
    GetProductsSuccess = '[SelfService] Get Products Success',
    GetProductsError = '[SelfService] Get Products Error',
}

export enum CustomerDataPlatformHttpActionTypes {
    SyncEnvironment = '[SelfService] Sync Environment',
    GetProducts = '[SelfService] Get Products',
}

//HTTP
export const SyncEnvironment = createAction(
    CustomerDataPlatformHttpActionTypes.SyncEnvironment,
    props<{ payload: { mode: string } }>()
);
export const GetProducts = createAction(CustomerDataPlatformHttpActionTypes.GetProducts);
// INNER
export const SyncEnvironmentSuccess = createAction(
    CustomerDataPlatformInnerActionTypes.SyncEnvironmentSuccess,
    props<{ payload: BasicResponse }>()
);

export const SyncEnvironmentError = createAction(
    CustomerDataPlatformInnerActionTypes.SyncEnvironmentError,
    props<{ payload: BasicResponse }>()
);

export const GetProductsSuccess = createAction(
    CustomerDataPlatformInnerActionTypes.GetProductsSuccess,
    props<{ payload: ResponseWrapper<SelfServiceProductsRes> }>()
);

export const GetProductsError = createAction(
    CustomerDataPlatformInnerActionTypes.GetProductsError,
    props<{ payload: BasicResponse }>()
);
