import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { BehaviorActionItem, BehaviorActionRes, BehaviorAttributeItem, BehaviorAttributeRes, BehaviorsListRes, SelectedBehaviorData, SelectedBehaviorRes, SelectedBehaviorSaveReq } from './behaviors.interface';

export enum SelfServiceBehaviorHttpActionTypes {
    LoadBehaviorData = '[SelfServiceBehavior] Load Behavior Data',
    DownloadBehaviorList = '[SelfServiceBehavior] Download Behavior List',
    GetBehaviorData = '[SelfServiceBehavior] Get Behavior Data',
    GetBehaviorAttributes = '[SelfServiceBehavior] Get Behavior Attributes',
    DeleteBehavior = '[SelfServiceBehavior] Delete Behavior',
    DeleteBehaviorAttribute = '[SelfServiceBehavior] Delete Behavior Attribute',
    GetBehaviorActions = '[SelfServiceBehavior] Get Behavior Actions',
    GetBehaviorTypes = '[SelfServiceBehavior] Get Behavior Types',
    GetBehaviorTypeActions = '[SelfServiceBehavior] Get Behavior Type Actions',
    GetSelectedBehavior = '[SelfServiceBehavior] Get Selected Behavior',
    SaveSelectedBehavior = '[SelfServiceBehavior] Save Selected Behavior',
    GetSelectedBehaviorAttribute = '[SelfServiceBehavior] Get Selected Behavior Attribute',
    GetAttributeTypes = '[SelfServiceBehavior] Get Attribute Types',
    SaveBehaviorAttribute = '[SelfServiceBehavior] Save Behavior Attribute',
    GetSelectedBehaviorAction = '[SelfServiceBehavior] Get Selected Behavior Action',
    SaveSelectedBehaviorAction = '[SelfServiceBehavior] Save Selected Behavior Action',
    GetActionTypes = '[SelfServiceBehavior] Get Action Types',
}

export enum SelfServiceBehaviorInnerActionTypes {
    LoadBehaviorDataSuccess = '[SelfServiceBehavior] Load Behavior Data Success',
    LoadBehaviorDataError = '[SelfServiceBehavior] Load Behavior Data Error',
    DownloadBehaviorListSuccess = '[SelfServiceBehavior] Download Behavior List Success',
    DownloadBehaviorListError = '[SelfServiceBehavior] Download Behavior List Error',
    GetBehaviorDataSuccess = '[SelfServiceBehavior] Get Behavior Data Success',
    GetBehaviorDataError = '[SelfServiceBehavior] Get Behavior Data Error',
    GetBehaviorAttributesSuccess = '[SelfServiceBehavior] Get Behavior Attributes Success',
    GetBehaviorAttributesError = '[SelfServiceBehavior] Get Behavior Attributes Error',
    DeleteBehavioruccess = '[SelfServiceBehavior] Delete Behavior Success',
    DeleteBehaviorError = '[SelfServiceBehavior] Delete Behavior Error',
    ClearBehaviorData = '[SelfServiceBehavior] Clear Behavior Data',
    DeleteBehaviorAttributeSuccess = '[SelfServiceBehavior] Delete Behavior Attribute Success',
    DeleteBehaviorAttributeError = '[SelfServiceBehavior] Delete Behavior Attribute Error',
    GetBehaviorActionsSuccess = '[SelfServiceBehavior] Get Behavior Actions Success',
    GetBehaviorActionsError = '[SelfServiceBehavior] Get Behavior Actions Error',
    GetBehaviorTypesSuccess = '[SelfServiceBehavior] Get Behavior Types Success',
    GetBehaviorTypesError = '[SelfServiceBehavior] Get Behavior Types Error',
    GetBehaviorTypeActionsSuccess = '[SelfServiceBehavior] Get Behavior Type Actions Success',
    GetBehaviorTypeActionsError = '[SelfServiceBehavior] Get Behavior Type Actions Error',
    GetSelectedBehaviorSuccess = '[SelfServiceBehavior] Get Selected Behavior Success',
    GetSelectedBehaviorError = '[SelfServiceBehavior] Get Selected Behavior Error',
    ClearSelectedBehavior = '[SelfServiceBehavior] Clear Selected Behavior',
    SaveSelectedBehaviorSuccess = '[SelfServiceBehavior] Save Selected Behavior Success',
    SaveSelectedBehaviorError = '[SelfServiceBehavior] Save Selected Behavior Error',
    GetSelectedBehaviorAttributeSuccess = '[SelfServiceBehavior] Get Selected Behavior Attribute Success',
    GetSelectedBehaviorAttributeError = '[SelfServiceBehavior] Get Selected Behavior Attribute Error',
    GetAttributeTypesSuccess = '[SelfServiceBehavior] Get Attribute Types Success',
    GetAttributeTypesError = '[SelfServiceBehavior] Get Attribute Types Error',
    SaveBehaviorAttributeSuccess = '[SelfServiceBehavior] Save Behavior Attribute Success',
    SaveBehaviorAttributeError = '[SelfServiceBehavior] Save Behavior Attribute Error',
    ClearBehaviorAttribute = '[SelfServiceBehavior] Clear Behavior Attribute',
    GetSelectedBehaviorActionSuccess = '[SelfServiceBehavior] Get Selected Behavior Action Success',
    GetSelectedBehaviorActionError = '[SelfServiceBehavior] Get Selected Behavior Action Error',
    SaveSelectedBehaviorActionSuccess = '[SelfServiceBehavior] Save Selected Behavior Action Success',
    SaveSelectedBehaviorActionError = '[SelfServiceBehavior] Save Selected Behavior Action Error',
    ClearBehaviorActionState = '[SelfServiceBehavior] Clear Behavior Action State',
    GetActionTypesSuccess = '[SelfServiceBehavior] Get Action Types Success',
    GetActionTypesError = '[SelfServiceBehavior] Get Action Types Error',
}

// ===== HTTP =====
export const LoadBehaviorData = createAction(
    SelfServiceBehaviorHttpActionTypes.LoadBehaviorData,
    props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number, singlePage?: boolean} }>()
);
export const DownloadBehaviorList = createAction(SelfServiceBehaviorHttpActionTypes.DownloadBehaviorList);
export const GetBehaviorData = createAction(
    SelfServiceBehaviorHttpActionTypes.GetBehaviorData,
    props<{ payload: {behaviorId: number} }>()
);
export const DeleteBehavior = createAction(
    SelfServiceBehaviorHttpActionTypes.DeleteBehavior,
    props<{ payload: {behaviorId: number} }>()
);
export const GetBehaviorAttributes = createAction(
    SelfServiceBehaviorHttpActionTypes.GetBehaviorAttributes,
    props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number} }>()
);
export const DeleteBehaviorAttribute = createAction(
    SelfServiceBehaviorHttpActionTypes.DeleteBehaviorAttribute,
    props<{ payload: {behaviorAttributeTypeId: number} }>()
);
export const GetBehaviorActions = createAction(
    SelfServiceBehaviorHttpActionTypes.GetBehaviorActions,
    props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number} }>()
);
export const GetBehaviorTypes = createAction(SelfServiceBehaviorHttpActionTypes.GetBehaviorTypes);
export const GetBehaviorTypeActions = createAction(
    SelfServiceBehaviorHttpActionTypes.GetBehaviorTypeActions,
    props<{ payload: {typeId: number} }>()
);
export const GetSelectedBehavior = createAction(
    SelfServiceBehaviorHttpActionTypes.GetSelectedBehavior,
    props<{ payload: {behaviorId: number} }>()
);
export const SaveSelectedBehavior = createAction(
    SelfServiceBehaviorHttpActionTypes.SaveSelectedBehavior,
    props<{ payload: {behaviorId: number, behaviorName: string, typeId: number, actionId: number, productId?: number}}>()
);
export const GetSelectedBehaviorAttribute = createAction(
    SelfServiceBehaviorHttpActionTypes.GetSelectedBehaviorAttribute,
    props<{ payload: {behaviorAttributeTypeId: number} }>()
);
export const GetAttributeTypes = createAction(SelfServiceBehaviorHttpActionTypes.GetAttributeTypes);
export const SaveBehaviorAttribute = createAction(
    SelfServiceBehaviorHttpActionTypes.SaveBehaviorAttribute,
    props<{ payload: SelectedBehaviorSaveReq }>()
);
export const GetSelectedBehaviorAction = createAction(
    SelfServiceBehaviorHttpActionTypes.GetSelectedBehaviorAction,
    props<{ payload: {behaviorActionId: number} }>()
);
export const SaveSelectedBehaviorAction = createAction(
    SelfServiceBehaviorHttpActionTypes.SaveSelectedBehaviorAction,
    props<{ payload: {behaviorActionId: number, behaviorActionName: string, typeId: number}}>()
);
export const GetActionTypes = createAction(SelfServiceBehaviorHttpActionTypes.GetActionTypes);
// ====== INNER ======
export const LoadBehaviorDataSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.LoadBehaviorDataSuccess,
    props<{ payload: ResponseWrapper<BehaviorsListRes> }>()
);
export const LoadBehaviorDataError = createAction(
    SelfServiceBehaviorInnerActionTypes.LoadBehaviorDataError,
    props<{ payload: BasicResponse }>()
);
export const DownloadBehaviorListSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.DownloadBehaviorListSuccess,
    props<{ payload: Blob }>()
);
export const DownloadBehaviorListError = createAction(
    SelfServiceBehaviorInnerActionTypes.DownloadBehaviorListError,
    props<{ payload: BasicResponse }>()
);
export const GetBehaviorDataSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.GetBehaviorDataSuccess,
    props<{ payload: ResponseWrapper<SelectedBehaviorRes> }>()
);
export const GetBehaviorDataError = createAction(
    SelfServiceBehaviorInnerActionTypes.GetBehaviorDataError,
    props<{ payload: BasicResponse }>()
);
export const DeleteBehavioruccess = createAction(
    SelfServiceBehaviorInnerActionTypes.DeleteBehavioruccess,
    props<{ payload: BasicResponse }>()
);
export const DeleteBehaviorError = createAction(
    SelfServiceBehaviorInnerActionTypes.DeleteBehaviorError,
    props<{ payload: BasicResponse }>()
);
export const ClearBehaviorData = createAction(SelfServiceBehaviorInnerActionTypes.ClearBehaviorData);
export const GetBehaviorAttributesSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.GetBehaviorAttributesSuccess,
    props<{ payload: ResponseWrapper<BehaviorAttributeRes> }>()
);
export const GetBehaviorAttributesError = createAction(
    SelfServiceBehaviorInnerActionTypes.GetBehaviorAttributesError,
    props<{ payload: BasicResponse }>()
);
export const DeleteBehaviorAttributeSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.DeleteBehaviorAttributeSuccess,
    props<{ payload: BasicResponse }>()
);
export const DeleteBehaviorAttributeError = createAction(
    SelfServiceBehaviorInnerActionTypes.DeleteBehaviorAttributeError,
    props<{ payload: BasicResponse }>()
);
export const GetBehaviorActionsSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.GetBehaviorActionsSuccess,
    props<{ payload: ResponseWrapper<BehaviorActionRes> }>()
);
export const GetBehaviorActionsError = createAction(
    SelfServiceBehaviorInnerActionTypes.GetBehaviorActionsError,
    props<{ payload: BasicResponse }>()
);
export const GetBehaviorTypesSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.GetBehaviorTypesSuccess,
    props<{ payload: ResponseWrapper<Record<string, string>> }>()
);
export const GetBehaviorTypesError = createAction(
    SelfServiceBehaviorInnerActionTypes.GetBehaviorTypesError,
    props<{ payload: BasicResponse }>()
);
export const GetBehaviorTypeActionsSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.GetBehaviorTypeActionsSuccess,
    props<{ payload: ResponseWrapper<Record<string, string>> }>()
);
export const GetBehaviorTypeActionsError = createAction(
    SelfServiceBehaviorInnerActionTypes.GetBehaviorTypeActionsError,
    props<{ payload: BasicResponse }>()
);
export const GetSelectedBehaviorSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.GetSelectedBehaviorSuccess,
    props<{ payload: ResponseWrapper<SelectedBehaviorData> }>()
);
export const GetSelectedBehaviorError = createAction(
    SelfServiceBehaviorInnerActionTypes.GetSelectedBehaviorError,
    props<{ payload: BasicResponse }>()
);
export const ClearSelectedBehavior = createAction(SelfServiceBehaviorInnerActionTypes.ClearSelectedBehavior);
export const SaveSelectedBehaviorSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.SaveSelectedBehaviorSuccess,
    props<{ payload: BasicResponse }>()
);
export const SaveSelectedBehaviorError = createAction(
    SelfServiceBehaviorInnerActionTypes.SaveSelectedBehaviorError,
    props<{ payload: BasicResponse }>()
);
export const GetSelectedBehaviorAttributeSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.GetSelectedBehaviorAttributeSuccess,
    props<{ payload: ResponseWrapper<BehaviorAttributeItem> }>()
);
export const GetSelectedBehaviorAttributeError = createAction(
    SelfServiceBehaviorInnerActionTypes.GetSelectedBehaviorAttributeError,
    props<{ payload: BasicResponse }>()
);
export const GetAttributeTypesSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.GetAttributeTypesSuccess,
    props<{ payload: ResponseWrapper<Record<string, string>> }>()
);
export const GetAttributeTypesError = createAction(
    SelfServiceBehaviorInnerActionTypes.GetAttributeTypesError,
    props<{ payload: BasicResponse }>()
);
export const SaveBehaviorAttributeSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.SaveBehaviorAttributeSuccess,
    props<{ payload: BasicResponse }>()
);
export const SaveBehaviorAttributeError = createAction(
    SelfServiceBehaviorInnerActionTypes.SaveBehaviorAttributeError,
    props<{ payload: BasicResponse }>()
);
export const ClearBehaviorAttribute = createAction(SelfServiceBehaviorInnerActionTypes.ClearBehaviorAttribute);
export const GetSelectedBehaviorActionSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.GetSelectedBehaviorActionSuccess,
    props<{ payload: ResponseWrapper<BehaviorActionItem> }>()
);
export const GetSelectedBehaviorActionError = createAction(
    SelfServiceBehaviorInnerActionTypes.GetSelectedBehaviorActionError,
    props<{ payload: BasicResponse }>()
);
export const SaveSelectedBehaviorActionSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.SaveSelectedBehaviorActionSuccess,
    props<{ payload: BasicResponse }>()
);
export const SaveSelectedBehaviorActionError = createAction(
    SelfServiceBehaviorInnerActionTypes.SaveSelectedBehaviorActionError,
    props<{ payload: BasicResponse }>()
);
export const ClearBehaviorActionState = createAction(SelfServiceBehaviorInnerActionTypes.ClearBehaviorActionState);
export const GetActionTypesSuccess = createAction(
    SelfServiceBehaviorInnerActionTypes.GetActionTypesSuccess,
    props<{ payload: ResponseWrapper<Record<string, string>> }>()
);
export const GetActionTypesError = createAction(
    SelfServiceBehaviorInnerActionTypes.GetActionTypesError,
    props<{ payload: BasicResponse }>()
);
