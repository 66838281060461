import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { DeploymentDesignation, DeploymentProductsListRes, DeploymentTypesWithoutProductRes, SelectedDeploymentProductData } from './deployment-products.interface';

export enum SelfServiceDeployemntProductsHttpActionTypes {
    LoadDeployemntProductsData = '[SelfServiceDeployemntProducts] Load DeployemntProducts Data',
    DownloadDeployemntProductsList = '[SelfServiceDeployemntProducts] Download DeployemntProducts List',
    DeleteDeployemntProducts = '[SelfServiceDeployemntProducts] Delete DeployemntProducts',
    GetProductDetails = '[SelfServiceDeployemntProducts] Get Product Details',
    CreateDeploymentProduct = '[SelfServiceDeployemntProducts] Create Deployment Product',
    UpdateEmailDeploymentProduct = '[SelfServiceDeployemntProducts] Update Email Deployment Product',
    GetDeploymentTypesWithoutProducts = '[SelfServiceDeployemntProducts] Get Deployment Types Without Products',
    GetDeploymentDesignations = '[SelfServiceDeployemntProducts] Get Deployment Designations',
    GetDeploymentDomains = '[SelfServiceDeployemntProducts] Get Deployment Domains',
}

export enum SelfServiceDeployemntProductsInnerActionTypes {
    LoadDeployemntProductsDataSuccess = '[SelfServiceDeployemntProducts] Load DeployemntProducts Data Success',
    LoadDeployemntProductsDataError = '[SelfServiceDeployemntProducts] Load DeployemntProducts Data Error',
    DownloadDeployemntProductsListSuccess = '[SelfServiceDeployemntProducts] Download DeployemntProducts List Success',
    DownloadDeployemntProductsListError = '[SelfServiceDeployemntProducts] Download DeployemntProducts List Error',
    GetDeployemntProductsDataSuccess = '[SelfServiceDeployemntProducts] Get DeployemntProducts Data Success',
    GetDeployemntProductsDataError = '[SelfServiceDeployemntProducts] Get DeployemntProducts Data Error',
    DeleteDeployemntProductsuccess = '[SelfServiceDeployemntProducts] Delete DeployemntProducts Success',
    DeleteDeployemntProductsError = '[SelfServiceDeployemntProducts] Delete DeployemntProducts Error',
    ClearDeployemntProductsData = '[SelfServiceDeployemntProducts] Clear DeployemntProducts Data',
    GetProductDetailsSuccess = '[SelfServiceDeployemntProducts] Get Product Details Success',
    GetProductDetailsError = '[SelfServiceDeployemntProducts] Get Product Details Error',
    CreateDeploymentProductSuccess = '[SelfServiceDeployemntProducts] Create Deployment Product Success',
    CreateDeploymentProductError = '[SelfServiceDeployemntProducts] Create Deployment Product Error',
    UpdateEmailDeploymentProductSuccess = '[SelfServiceDeployemntProducts] Update Email Deployment Product Success',
    UpdateEmailDeploymentProductError = '[SelfServiceDeployemntProducts] Update Email Deployment Product Error',
    GetDeploymentTypesWithoutProductsSuccess = '[SelfServiceDeployemntProducts] Get Deployment Types Without Products Success',
    GetDeploymentTypesWithoutProductsError = '[SelfServiceDeployemntProducts] Get Deployment Types Without Products Error',
    GetDeploymentDesignationsSuccess = '[SelfServiceDeployemntProducts] Get Deployment Designations Success',
    GetDeploymentDesignationsError = '[SelfServiceDeployemntProducts] Get Deployment Designations Error',
    GetDeploymentDomainsSuccess = '[SelfServiceDeployemntProducts] Get Deployment Domains Success',
    GetDeploymentDomainsError = '[SelfServiceDeployemntProducts] Get Deployment Domains Error',
}

// ===== HTTP =====
export const LoadDeploymentProducts = createAction(
    SelfServiceDeployemntProductsHttpActionTypes.LoadDeployemntProductsData,
    props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number, status?: string, productType?: number} }>()
);
export const DownloadDeployemntProductsList = createAction(SelfServiceDeployemntProductsHttpActionTypes.DownloadDeployemntProductsList);

export const DeleteDeployemntProducts = createAction(
    SelfServiceDeployemntProductsHttpActionTypes.DeleteDeployemntProducts,
    props<{ payload: {DeployemntProductsId: number} }>()
);

export const GetProductDetails = createAction(
    SelfServiceDeployemntProductsHttpActionTypes.GetProductDetails,
    props<{ payload: {productId: number} }>()
);

export const CreateDeploymentProduct = createAction(
    SelfServiceDeployemntProductsHttpActionTypes.CreateDeploymentProduct,
    props<{ payload: SelectedDeploymentProductData }>()
);

export const UpdateEmailDeploymentProduct = createAction(
    SelfServiceDeployemntProductsHttpActionTypes.UpdateEmailDeploymentProduct,
    props<{ payload: SelectedDeploymentProductData }>()
);

export const GetDeploymentTypesWithoutProducts = createAction(SelfServiceDeployemntProductsHttpActionTypes.GetDeploymentTypesWithoutProducts,
    props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number, singlePage?: boolean} }>()
);
export const GetDeploymentDesignations = createAction(SelfServiceDeployemntProductsHttpActionTypes.GetDeploymentDesignations);
export const GetDeploymentDomains = createAction(SelfServiceDeployemntProductsHttpActionTypes.GetDeploymentDomains);
// ====== INNER ======
export const LoadDeployemntProductsDataSuccess = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.LoadDeployemntProductsDataSuccess,
    props<{ payload: ResponseWrapper<DeploymentProductsListRes> }>()
);
export const LoadDeployemntProductsDataError = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.LoadDeployemntProductsDataError,
    props<{ payload: BasicResponse }>()
);
export const DownloadDeployemntProductsListSuccess = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.DownloadDeployemntProductsListSuccess
);
export const DownloadDeployemntProductsListError = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.DownloadDeployemntProductsListError,
    props<{ payload: BasicResponse }>()
);
export const DeleteDeployemntProductsuccess = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.DeleteDeployemntProductsuccess,
    props<{ payload: BasicResponse }>()
);
export const DeleteDeployemntProductsError = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.DeleteDeployemntProductsError,
    props<{ payload: BasicResponse }>()
);
export const ClearDeployemntProductsData = createAction(SelfServiceDeployemntProductsInnerActionTypes.ClearDeployemntProductsData);
export const GetProductDetailsSuccess = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.GetProductDetailsSuccess,
    props<{ payload: ResponseWrapper<SelectedDeploymentProductData> }>()
);
export const GetProductDetailsError = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.GetProductDetailsError,
    props<{ payload: BasicResponse }>()
);
export const CreateDeploymentProductSuccess = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.CreateDeploymentProductSuccess,
    props<{ payload: ResponseWrapper<SelectedDeploymentProductData> }>()
);
export const CreateDeploymentProductError = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.CreateDeploymentProductError,
    props<{ payload: BasicResponse }>()
);
export const UpdateEmailDeploymentProductSuccess = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.UpdateEmailDeploymentProductSuccess,
    props<{ payload: ResponseWrapper<SelectedDeploymentProductData> }>()
);
export const UpdateEmailDeploymentProductError = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.UpdateEmailDeploymentProductError,
    props<{ payload: BasicResponse }>()
);
export const GetDeploymentTypesWithoutProductsSuccess = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.GetDeploymentTypesWithoutProductsSuccess,
    props<{ payload: ResponseWrapper<DeploymentTypesWithoutProductRes> }>()
);
export const GetDeploymentTypesWithoutProductsError = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.GetDeploymentTypesWithoutProductsError,
    props<{ payload: BasicResponse }>()
);
export const GetDeploymentDesignationsSuccess = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.GetDeploymentDesignationsSuccess,
    props<{ payload: ResponseWrapper<Array<DeploymentDesignation>> }>()
);
export const GetDeploymentDesignationsError = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.GetDeploymentDesignationsError,
    props<{ payload: BasicResponse }>()
);
export const GetDeploymentDomainsSuccess = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.GetDeploymentDomainsSuccess,
    props<{ payload: ResponseWrapper<Array<DeploymentDesignation>> }>()
);
export const GetDeploymentDomainsError = createAction(
    SelfServiceDeployemntProductsInnerActionTypes.GetDeploymentDomainsError,
    props<{ payload: BasicResponse }>()
);
