import { createAction, props } from '@ngrx/store';
import { BasicResponse } from 'common';
import { LogoOptionBase } from './logos.interface';

export enum SelfServiceLogosHttpActionTypes {
    LoadLogosData = '[SelfServiceLogos] Load Logos Data',
    LoadOptions = '[SelfServiceLogos] Load Options',
}

export enum SelfServiceLogosInnerActionTypes {
    LoadLogosDataSuccess = '[SelfServiceLogos] Load Logos Data Success',

    LoadOptionsSuccess = '[SelfServiceLogos] Load Options Success',
    LoadOptionsError = '[SelfServiceLogos] Load Options Error',

    UpdateLogosData = '[SelfServiceLogos] Update Logos Data',
    UpdateLogosDataSuccess = '[SelfServiceLogos] Update Logos Data Success',
    UpdateLogosDataError = '[SelfServiceLogos] Update Logos Data Error',
}

// ===== HTTP =====
export const LoadLogosData = createAction(
    SelfServiceLogosHttpActionTypes.LoadLogosData,
    props<{ payload: { field?: string, fieldId?: string } }>()
);

export const LoadOptions = createAction(
    SelfServiceLogosHttpActionTypes.LoadOptions,
    props<{ payload: { field?: string} }>()
);

export const UpdateLogosData = createAction(
    SelfServiceLogosInnerActionTypes.UpdateLogosData,
    props<{ payload: { field: string, fieldId: string, file: File} }>()
);

// ====== INNER ======
export const LoadOptionsSuccess = createAction(
    SelfServiceLogosInnerActionTypes.LoadOptionsSuccess,
    props<{ payload: { options: LogoOptionBase[], field: string }  }>()
);

export const LoadOptionsError = createAction(
    SelfServiceLogosInnerActionTypes.LoadOptionsError,
    props<{ payload: BasicResponse }>()
);

export const UpdateLogosDataSuccess = createAction(
    SelfServiceLogosInnerActionTypes.UpdateLogosDataSuccess,
    props<{ payload: {url: string, field: string} }>()
);

export const UpdateLogosDataError = createAction(
    SelfServiceLogosInnerActionTypes.UpdateLogosDataError,
    props<{ payload: BasicResponse }>()
);

export const LoadLogosDataSuccess = createAction(
    SelfServiceLogosInnerActionTypes.LoadLogosDataSuccess,
    props<{ payload: { url: string, message: string, field?: string, fieldId?: string } }>()
);
