import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { CommonQuerySearchReq, CommonReq, Product, SelectedProfileRes } from './profiles.interface';

export enum SelfServiceProfilesHttpActionTypes {
    LoadProfilesData = '[SelfServiceProfiles] Load Profiles Data',
    SearchQueries = '[SelfServiceProfiles] Search Queries',
    ChangeProfilePriority = '[SelfServiceProfiles] Change Profile Priority',
    GetSelectedProfileDetails = '[SelfServiceProfiles] Get Selected Profile Details',
    GetContactRules = '[SelfServiceProfiles] Get Contact Rules',
    GetNameSpaces = '[SelfServiceProfiles] Get Name Spaces',
    SaveProfile = '[SelfServiceProfiles] Save Profile',
    GetProfileBehaviors = '[SelfServiceProfiles] Get Profile Behaviors',
    GetProfileDemographics = '[SelfServiceProfiles] Get Profile Demographics',
    GetProfileProducts = '[SelfServiceProfiles] Get Profile Products',
}

export enum SelfServiceProfilesInnerActionTypes {
    LoadProfilesDataSuccess = '[SelfServiceProfiles] Load Profiles Data Success',
    LoadProfilesDataDataError = '[SelfServiceProfiles] Load Profiles Data Error',
    ClearSearchQueriesResults = '[SelfServiceProfiles] Clear Search Queries Results',
    SearchQueriesSuccess = '[SelfServiceProfiles] Search Queries Success',
    SearchQueriesError = '[SelfServiceProfiles] Search Queries Error',
    ChangeProfilePrioritySuccess = '[SelfServiceProfiles] Change Profile Priority Success',
    ChangeProfilePriorityError = '[SelfServiceProfiles] Change Profile Priority Error',
    GetSelectedProfileDetailsSuccess = '[SelfServiceProfiles] Get Selected Profile Details Success',
    GetSelectedProfileDetailsError = '[SelfServiceProfiles] Get Selected Profile Details Error',
    GetContactRulesSuccess = '[SelfServiceProfiles] Get Contact Rules Success',
    GetContactRulesError = '[SelfServiceProfiles] Get Contact Rules Error',
    GetNameSpacesSuccess = '[SelfServiceProfiles] Get Name Spaces Success',
    GetNameSpacesError = '[SelfServiceProfiles] Get Name Spaces Error',
    ClearSelectedProfileDetails = '[SelfServiceProfiles] Clear Selected Profile Details',
    SaveProfileSuccess = '[SelfServiceProfiles] Save Profile Success',
    SaveProfileError = '[SelfServiceProfiles] Save Profile Error',
    GetProfileBehaviorsSuccess = '[SelfServiceProfiles] Get Profile Behaviors Success',
    GetProfileBehaviorsError = '[SelfServiceProfiles] Get Profile Behaviors Error',
    GetProfileDemographicsSuccess = '[SelfServiceProfiles] Get Profile Demographics Success',
    GetProfileDemographicsError = '[SelfServiceProfiles] Get Profile Demographics Error',
    GetProfileProductsSuccess = '[SelfServiceProfiles] Get Profile Products Success',
    GetProfileProductsError = '[SelfServiceProfiles] Get Profile Products Error',
}

// ===== HTTP =====
export const LoadProfilesData = createAction(
    SelfServiceProfilesHttpActionTypes.LoadProfilesData,
    props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number} }>()
);
export const SearchQueries = createAction(SelfServiceProfilesHttpActionTypes.SearchQueries, props<{ payload: CommonQuerySearchReq }>());
export const ChangeProfilePriority = createAction(SelfServiceProfilesHttpActionTypes.ChangeProfilePriority, props<{ payload: { id: number, direction: string }, reloadPayload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number} }>());
export const GetSelectedProfileDetails = createAction(SelfServiceProfilesHttpActionTypes.GetSelectedProfileDetails, props<{ payload: { dataViewId: number } }>());
export const GetContactRules = createAction(SelfServiceProfilesHttpActionTypes.GetContactRules, props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number} }>());
export const GetNameSpaces = createAction(SelfServiceProfilesHttpActionTypes.GetNameSpaces, props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number} }>());
export const SaveProfile = createAction(SelfServiceProfilesHttpActionTypes.SaveProfile, props<{ payload: SelectedProfileRes }>());
export const GetProfileBehaviors = createAction(SelfServiceProfilesHttpActionTypes.GetProfileBehaviors, props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number} }>());
export const GetProfileDemographics = createAction(SelfServiceProfilesHttpActionTypes.GetProfileDemographics, props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number} }>());
export const GetProfileProducts = createAction(SelfServiceProfilesHttpActionTypes.GetProfileProducts, props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number} }>());
// ====== INNER ======
export const LoadProfilesDataSuccess = createAction(
    SelfServiceProfilesInnerActionTypes.LoadProfilesDataSuccess,
    props<{ payload: ResponseWrapper<Product[]> }>()
);
export const LoadProfilesDataError = createAction(
    SelfServiceProfilesInnerActionTypes.LoadProfilesDataDataError,
    props<{ payload: BasicResponse }>()
);
export const ClearSearchQueriesResults = createAction(SelfServiceProfilesInnerActionTypes.ClearSearchQueriesResults);
export const SearchQueriesSuccess = createAction(SelfServiceProfilesInnerActionTypes.SearchQueriesSuccess, props<{ payload: ResponseWrapper<Array<CommonQuerySearchReq>> }>());
export const SearchQueriesError = createAction(SelfServiceProfilesInnerActionTypes.SearchQueriesError, props<{ error: BasicResponse }>());
export const ChangeProfilePrioritySuccess = createAction(SelfServiceProfilesInnerActionTypes.ChangeProfilePrioritySuccess, props<{ payload: ResponseWrapper<BasicResponse> }>());
export const ChangeProfilePriorityError = createAction(SelfServiceProfilesInnerActionTypes.ChangeProfilePriorityError, props<{ error: BasicResponse }>());
export const GetSelectedProfileDetailsSuccess = createAction(SelfServiceProfilesInnerActionTypes.GetSelectedProfileDetailsSuccess, props<{ payload: ResponseWrapper<SelectedProfileRes> }>());
export const GetSelectedProfileDetailsError = createAction(SelfServiceProfilesInnerActionTypes.GetSelectedProfileDetailsError, props<{ error: BasicResponse }>());
export const GetContactRulesSuccess = createAction(SelfServiceProfilesInnerActionTypes.GetContactRulesSuccess, props<{ payload: ResponseWrapper<CommonReq> }>());
export const GetContactRulesError = createAction(SelfServiceProfilesInnerActionTypes.GetContactRulesError, props<{ error: BasicResponse }>());
export const GetNameSpacesSuccess = createAction(SelfServiceProfilesInnerActionTypes.GetNameSpacesSuccess, props<{ payload: ResponseWrapper<CommonReq> }>());
export const GetNameSpacesError = createAction(SelfServiceProfilesInnerActionTypes.GetNameSpacesError, props<{ error: BasicResponse }>());
export const ClearSelectedProfileDetails = createAction(SelfServiceProfilesInnerActionTypes.ClearSelectedProfileDetails);
export const SaveProfileSuccess = createAction(SelfServiceProfilesInnerActionTypes.SaveProfileSuccess, props<{ payload: ResponseWrapper<BasicResponse> }>());
export const SaveProfileError = createAction(SelfServiceProfilesInnerActionTypes.SaveProfileError, props<{ error: BasicResponse, payload: SelectedProfileRes }>());
export const GetProfileBehaviorsSuccess = createAction(SelfServiceProfilesInnerActionTypes.GetProfileBehaviorsSuccess, props<{ payload: ResponseWrapper<CommonReq> }>());
export const GetProfileBehaviorsError = createAction(SelfServiceProfilesInnerActionTypes.GetProfileBehaviorsError, props<{ error: BasicResponse }>());
export const GetProfileDemographicsSuccess = createAction(SelfServiceProfilesInnerActionTypes.GetProfileDemographicsSuccess, props<{ payload: ResponseWrapper<CommonReq> }>());
export const GetProfileDemographicsError = createAction(SelfServiceProfilesInnerActionTypes.GetProfileDemographicsError, props<{ error: BasicResponse }>());
export const GetProfileProductsSuccess = createAction(SelfServiceProfilesInnerActionTypes.GetProfileProductsSuccess, props<{ payload: ResponseWrapper<CommonReq> }>());
export const GetProfileProductsError = createAction(SelfServiceProfilesInnerActionTypes.GetProfileProductsError, props<{ error: BasicResponse }>());
