import { ResponseWrapper } from 'common';

export const skuManagementInitialState: SkuManagementState = {
    skuManagementData: {
        loading: false,
        data: null
    },
};

export interface SkuManagementState {
    skuManagementData: ResponseWrapper<SkuManagementData>;
    selectedSku?: ResponseWrapper<SkuManagementItem>;
}

export interface SkuManagementData {
    totalResultCount: number;
    items: Array<SkuManagementItem>;
}

export interface SkuManagementItem {
    storeDetailId?: number;
    sku?: string;
    typeId?: number;
    externalId?: number;
    description?: string;
    handle?: string;
    vendor?: string;
    productCategory?: string;
    createdDate?: string;
    changedDate?: string;
    imageUrl?: string;
    tags?: SkuTag[];
    storeDetailChildSchema?: SkuManagementItem[];
}
export interface SkuStore {
    storeId: number;
    typeId: number;
    externalId: number;
    description: string;
}

export interface SkuTag {
    tag: string;
    statusCode: number;
}

export interface VariantItem {
    stockKeepingUnit: string;
    name: string;
    value: string;
    price: string;
}

export const storeTypeMapping = {
    1: 'Shopify',
    2: 'WooCommerce'
};