import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { NoticePanelsRes, PatternHistoryDataRes, PatternMatching, ProductNoticeCampaignPricingRes, ProductNoticeSetupsRes, SelectedProductNoticeRes } from './product-notice.interface';

export enum SelfServiceProductNoticeHttpActionTypes {
    LoadProductNoticeData = '[SelfServiceProductNotice] Load Product Notice Data',
    GetProductNoticeData = '[SelfServiceProductNotice] Get Product Notice Data',
    GetNoticeTypes = '[SelfServiceProductNotice] Get Notice Types',
    GetNoticeProducts = '[SelfServiceProductNotice] Get Notice Products',
    GetNoticePatternMatching = '[SelfServiceProductNotice] Get Notice Pattern Matching',
    DownloadPatternMatching = '[SelfServiceProductNotice] Download Pattern Matching',
    GetProductNoticePanels = '[SelfServiceProductNotice] Get Product Notice Panels',
    DownloadPanels = '[SelfServiceProductNotice] Download Panels',
    GetProductNoticeCampaignPrices = '[SelfServiceProductNotice] Get Product Notice Campaign Prices',
    GetPatternHistory = '[SelfServiceProductNotice] Get Pattern History',
}

export enum SelfServiceProductNoticeInnerActionTypes {
    LoadProductNoticeDataSuccess = '[SelfServiceProductNotice] Load Product Notice Data Success',
    LoadProductNoticeDataError = '[SelfServiceProductNotice] Load Product Notice Data Error',
    GetProductNoticeDataSuccess = '[SelfServiceProductNotice] Get Product Notice Data Success',
    GetProductNoticeDataError = '[SelfServiceProductNotice] Get Product Notice Data Error',
    GetNoticeTypesSuccess = '[SelfServiceProductNotice] Get Notice Types Success',
    GetNoticeTypesError = '[SelfServiceProductNotice] Get Notice Types Error',
    GetNoticeProductsSuccess = '[SelfServiceProductNotice] Get Notice Products Success',
    GetNoticeProductsError = '[SelfServiceProductNotice] Get Notice Products Error',
    GetNoticePatternMatchingSuccess = '[SelfServiceProductNotice] Get Notice Pattern Matching Success',
    GetNoticePatternMatchingError = '[SelfServiceProductNotice] Get Notice Pattern Matching Error',
    DownloadPatternMatchingSuccess = '[SelfServiceProductNotice] Download Pattern Matching Success',
    DownloadPatternMatchingError = '[SelfServiceProductNotice] Download Pattern Matching Error',
    GetProductNoticePanelsSuccess = '[SelfServiceProductNotice] Get Product Notice Panels Success',
    GetProductNoticePanelsError = '[SelfServiceProductNotice] Get Product Notice Panels Error',
    DownloadPanelsSuccess = '[SelfServiceProductNotice] Download Panels Success',
    DownloadPanelsError = '[SelfServiceProductNotice] Download Panels Error',
    GetProductNoticeCampaignPricesSuccess = '[SelfServiceProductNotice] Get Product Notice Campaign Prices Success',
    GetProductNoticeCampaignPricesError = '[SelfServiceProductNotice] Get Product Notice Campaign Prices Error',
    GetPatternHistorySuccess = '[SelfServiceProductNotice] Get Pattern History Success',
    GetPatternHistoryError = '[SelfServiceProductNotice] Get Pattern History Error',
}

// ===== HTTP =====
export const LoadProductNoticeData = createAction(
    SelfServiceProductNoticeHttpActionTypes.LoadProductNoticeData,
    props<{ payload: { searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number, automated?: string, noticeType?: number, productId?: number } }>()
);

export const GetProductNoticeData = createAction(
    SelfServiceProductNoticeHttpActionTypes.GetProductNoticeData,
    props<{ payload: { noticeSetupId?: number } }>()
);

export const GetNoticeTypes = createAction(
    SelfServiceProductNoticeHttpActionTypes.GetNoticeTypes
);

export const GetNoticeProducts = createAction(
    SelfServiceProductNoticeHttpActionTypes.GetNoticeProducts
);

export const GetNoticePatternMatching = createAction(
    SelfServiceProductNoticeHttpActionTypes.GetNoticePatternMatching,
    props<{ payload: { noticeSetupId: number, numResults?: number, offset?: number, order?: string, sortBy?: string } }>()
);

export const DownloadPatternMatching = createAction(
    SelfServiceProductNoticeHttpActionTypes.DownloadPatternMatching,
    props<{ payload: { noticeSetupId: number } }>()
);

export const GetProductNoticePanels = createAction(
    SelfServiceProductNoticeHttpActionTypes.GetProductNoticePanels,
    props<{ payload: { noticeSetupId: number, numResults?: number, offset?: number, order?: string, sortBy?: string, scheduleGroupName?: string } }>()
);

export const DownloadPanels = createAction(
    SelfServiceProductNoticeHttpActionTypes.DownloadPanels,
    props<{ payload: { noticeSetupId: number } }>()
);

export const GetProductNoticeCampaignPrices = createAction(
    SelfServiceProductNoticeHttpActionTypes.GetProductNoticeCampaignPrices,
    props<{ payload: { noticeSetupId: number, numResults?: number, offset?: number } }>()
);

export const GetPatternHistory = createAction(SelfServiceProductNoticeHttpActionTypes.GetPatternHistory,
    props<{ payload: { noticeSetupId: number } }>()
);

// ====== INNER ======
export const LoadProductNoticeDataSuccess = createAction(
    SelfServiceProductNoticeInnerActionTypes.LoadProductNoticeDataSuccess,
    props<{ payload: ResponseWrapper<ProductNoticeSetupsRes> }>()
);
export const LoadProductNoticeDataError = createAction(
    SelfServiceProductNoticeInnerActionTypes.LoadProductNoticeDataError,
    props<{ payload: BasicResponse }>()
);
export const GetProductNoticeDataSuccess = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetProductNoticeDataSuccess,
    props<{ payload: ResponseWrapper<SelectedProductNoticeRes> }>()
);
export const GetProductNoticeDataError = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetProductNoticeDataError,
    props<{ payload: BasicResponse }>()
);
export const GetNoticeTypesSuccess = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetNoticeTypesSuccess,
    props<{ payload: ResponseWrapper<Array<{code: number, name: string}>>}>()
);

export const GetNoticeTypesError = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetNoticeTypesError,
    props<{ payload: BasicResponse }>()
);

export const GetNoticeProductsSuccess = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetNoticeProductsSuccess,
    props<{ payload: ResponseWrapper<Array<{code: number, name: string}>>}>()
);

export const GetNoticeProductsError = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetNoticeProductsError,
    props<{ payload: BasicResponse }>()
);

export const GetNoticePatternMatchingSuccess = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetNoticePatternMatchingSuccess,
    props<{ payload: PatternMatching }>()
);

export const GetNoticePatternMatchingError = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetNoticePatternMatchingError,
    props<{ payload: BasicResponse }>()
);

export const DownloadPatternMatchingSuccess = createAction(SelfServiceProductNoticeInnerActionTypes.DownloadPatternMatchingSuccess);

export const DownloadPatternMatchingError = createAction(
    SelfServiceProductNoticeInnerActionTypes.DownloadPatternMatchingError,
    props<{ payload: BasicResponse }>()
);

export const GetProductNoticePanelsSuccess = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetProductNoticePanelsSuccess,
    props<{ payload: NoticePanelsRes}>()
);

export const GetProductNoticePanelsError = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetProductNoticePanelsError,
    props<{ payload: BasicResponse }>()
);

export const DownloadPanelsSuccess = createAction(SelfServiceProductNoticeInnerActionTypes.DownloadPanelsSuccess);

export const DownloadPanelsError = createAction(
    SelfServiceProductNoticeInnerActionTypes.DownloadPanelsError,
    props<{ payload: BasicResponse }>()
);

export const GetProductNoticeCampaignPricesSuccess = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetProductNoticeCampaignPricesSuccess,
    props<{ payload: ProductNoticeCampaignPricingRes}>()
);

export const GetProductNoticeCampaignPricesError = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetProductNoticeCampaignPricesError,
    props<{ payload: BasicResponse }>()
);

export const GetPatternHistorySuccess = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetPatternHistorySuccess,
    props<{ payload: ResponseWrapper<PatternHistoryDataRes[]>}>()
);

export const GetPatternHistoryError = createAction(
    SelfServiceProductNoticeInnerActionTypes.GetPatternHistoryError,
    props<{ payload: BasicResponse }>()
);
