import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { DeploymentTypeListRes, DeploymentTypeProductList, SelectedDeploymentType } from './deployment-types.interface';

export enum SelfServiceDeploymentTypesHttpActionTypes {
    LoadDeploymentTypesData = '[SelfServiceDeploymentTypes] Load DeploymentTypes Data',
    DownloadDeploymentTypesList = '[SelfServiceDeploymentTypes] Download DeploymentTypes List',
    DeleteDeploymentTypes = '[SelfServiceDeploymentTypes] Delete DeploymentTypes',
    GetSelectedDeploymentTypeData = '[SelfServiceDeploymentTypes] Get Selected DeploymentType Data',
    SaveDeploymentType = '[SelfServiceDeploymentTypes] Save DeploymentType',
    GetDeploymentTypeProducts = '[SelfServiceDeploymentTypes] Get DeploymentType Products',
}

export enum SelfServiceDeploymentTypesInnerActionTypes {
    LoadDeploymentTypesDataSuccess = '[SelfServiceDeploymentTypes] Load DeploymentTypes Data Success',
    LoadDeploymentTypesDataError = '[SelfServiceDeploymentTypes] Load DeploymentTypes Data Error',
    DownloadDeploymentTypesListSuccess = '[SelfServiceDeploymentTypes] Download DeploymentTypes List Success',
    DownloadDeploymentTypesListError = '[SelfServiceDeploymentTypes] Download DeploymentTypes List Error',
    GetDeploymentTypesDataSuccess = '[SelfServiceDeploymentTypes] Get DeploymentTypes Data Success',
    GetDeploymentTypesDataError = '[SelfServiceDeploymentTypes] Get DeploymentTypes Data Error',
    DeleteDeploymentTypeSuccess = '[SelfServiceDeploymentTypes] Delete DeploymentTypes Success',
    DeleteDeploymentTypesError = '[SelfServiceDeploymentTypes] Delete DeploymentTypes Error',
    ClearDeploymentTypesData = '[SelfServiceDeploymentTypes] Clear DeploymentTypes Data',
    GetSelectedDeploymentTypeDataSuccess = '[SelfServiceDeploymentTypes] Get Selected DeploymentType Data Success',
    GetSelectedDeploymentTypeDataError = '[SelfServiceDeploymentTypes] Get Selected DeploymentType Data Error',
    SaveDeploymentTypeSuccess = '[SelfServiceDeploymentTypes] Save DeploymentType Success',
    SaveDeploymentTypeError = '[SelfServiceDeploymentTypes] Save DeploymentType Error',
    GetDeploymentTypeProductsSuccess = '[SelfServiceDeploymentTypes] Get DeploymentType Products Success',
    GetDeploymentTypeProductsError = '[SelfServiceDeploymentTypes] Get DeploymentType Products Error',
}

// ===== HTTP =====
export const LoadDeploymentTypes = createAction(
    SelfServiceDeploymentTypesHttpActionTypes.LoadDeploymentTypesData,
    props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number, singlePage?: boolean} }>()
);
export const DownloadDeploymentTypesList = createAction(SelfServiceDeploymentTypesHttpActionTypes.DownloadDeploymentTypesList);

export const DeleteDeploymentTypes = createAction(
    SelfServiceDeploymentTypesHttpActionTypes.DeleteDeploymentTypes,
    props<{ payload: { deploymentTypeId: number} }>()
);

export const GetSelectedDeploymentTypeData = createAction(
    SelfServiceDeploymentTypesHttpActionTypes.GetSelectedDeploymentTypeData,
    props<{ payload: { deploymentTypeId?: number } }>()
);

export const SaveDeploymentType = createAction(
    SelfServiceDeploymentTypesHttpActionTypes.SaveDeploymentType,
    props<{ payload: SelectedDeploymentType }>()
);

export const GetDeploymentTypeProducts = createAction(
    SelfServiceDeploymentTypesHttpActionTypes.GetDeploymentTypeProducts,
    props<{ payload: { deploymentTypeId?: number, searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number } }>()
);

// ====== INNER ======
export const LoadDeploymentTypesDataSuccess = createAction(
    SelfServiceDeploymentTypesInnerActionTypes.LoadDeploymentTypesDataSuccess,
    props<{ payload: ResponseWrapper<DeploymentTypeListRes> }>()
);
export const LoadDeploymentTypesDataError = createAction(
    SelfServiceDeploymentTypesInnerActionTypes.LoadDeploymentTypesDataError,
    props<{ payload: BasicResponse }>()
);
export const DownloadDeploymentTypesListSuccess = createAction(
    SelfServiceDeploymentTypesInnerActionTypes.DownloadDeploymentTypesListSuccess
);
export const DownloadDeploymentTypesListError = createAction(
    SelfServiceDeploymentTypesInnerActionTypes.DownloadDeploymentTypesListError,
    props<{ payload: BasicResponse }>()
);
export const DeleteDeploymentTypeSuccess = createAction(
    SelfServiceDeploymentTypesInnerActionTypes.DeleteDeploymentTypeSuccess,
    props<{ payload: BasicResponse }>()
);
export const DeleteDeploymentTypesError = createAction(
    SelfServiceDeploymentTypesInnerActionTypes.DeleteDeploymentTypesError,
    props<{ payload: BasicResponse }>()
);
export const ClearDeploymentTypesData = createAction(SelfServiceDeploymentTypesInnerActionTypes.ClearDeploymentTypesData);
export const GetSelectedDeploymentTypeDataSuccess = createAction(
    SelfServiceDeploymentTypesInnerActionTypes.GetSelectedDeploymentTypeDataSuccess,
    props<{ payload: ResponseWrapper<SelectedDeploymentType> }>()
);
export const GetSelectedDeploymentTypeDataError = createAction(
    SelfServiceDeploymentTypesInnerActionTypes.GetSelectedDeploymentTypeDataError,
    props<{ payload: BasicResponse }>()
);
export const SaveDeploymentTypeSuccess = createAction(
    SelfServiceDeploymentTypesInnerActionTypes.SaveDeploymentTypeSuccess,
    props<{ payload: BasicResponse }>()
);
export const SaveDeploymentTypeError = createAction(
    SelfServiceDeploymentTypesInnerActionTypes.SaveDeploymentTypeError,
    props<{ payload: BasicResponse }>()
);
export const GetDeploymentTypeProductsSuccess = createAction(
    SelfServiceDeploymentTypesInnerActionTypes.GetDeploymentTypeProductsSuccess,
    props<{ payload: ResponseWrapper<DeploymentTypeProductList> }>()
);

export const GetDeploymentTypeProductsError = createAction(
    SelfServiceDeploymentTypesInnerActionTypes.GetDeploymentTypeProductsError,
    props<{ payload: BasicResponse }>()
);
