import { createAction, props } from '@ngrx/store';
import { BasicResponse } from 'common';
import { CountryItem, SaveDatabaseDataPayload } from './database-settings.interface';

export enum SelfServiceDatabaseSettingsHttpActionTypes {
    LoadDatabaseSettingsData = '[SelfServiceDatabaseSettings] Load Database Settings Data',
    SaveDatabaseSettings = '[SelfServiceDatabaseSettings] Save Database Settings',
    GetCountryList = '[SelfServiceDatabaseSettings] Get Country List'
}

export enum SelfServiceDatabaseSettingsInnerActionTypes {
    LoadDatabaseSettingsDataSuccess = '[SelfServiceDatabaseSettings] Load Database Settings Data Success',
    LoadDatabaseSettingsDataError = '[SelfServiceDatabaseSettings] Load Database Settings Data Error',
    SaveDatabaseSettingsSuccess = '[SelfServiceDatabaseSettings] Save Database Settings Success',
    SaveDatabaseSettingsError = '[SelfServiceDatabaseSettings] Save Database Settings Error',
    GetCountryListSuccess = '[SelfServiceDatabaseSettings] Get Country List Success',
    GetCountryListError = '[SelfServiceDatabaseSettings] Get Country List Error'
}

// ===== HTTP =====
export const LoadDatabaseSettingsData = createAction(SelfServiceDatabaseSettingsHttpActionTypes.LoadDatabaseSettingsData);
export const SaveDatabaseSettings = createAction(SelfServiceDatabaseSettingsHttpActionTypes.SaveDatabaseSettings,
    props<{ payload: SaveDatabaseDataPayload}>()
);
export const GetCountryList = createAction(
    SelfServiceDatabaseSettingsHttpActionTypes.GetCountryList
);
// ====== INNER ======
export const LoadDatabaseSettingsDataSuccess = createAction(
    SelfServiceDatabaseSettingsInnerActionTypes.LoadDatabaseSettingsDataSuccess,
    props<{ payload: SaveDatabaseDataPayload }>()
);
export const LoadDatabaseSettingsDataError = createAction(
    SelfServiceDatabaseSettingsInnerActionTypes.LoadDatabaseSettingsDataError,
    props<{ payload: BasicResponse }>()
);
export const SaveDatabaseSettingsSuccess = createAction(
    SelfServiceDatabaseSettingsInnerActionTypes.SaveDatabaseSettingsSuccess,
    props<{ payload: SaveDatabaseDataPayload }>()
);
export const SaveDatabaseSettingsError = createAction(
    SelfServiceDatabaseSettingsInnerActionTypes.SaveDatabaseSettingsError,
    props<{ payload: BasicResponse }>()
);
export const GetCountryListSuccess = createAction(
    SelfServiceDatabaseSettingsInnerActionTypes.GetCountryListSuccess,
    props<{ payload: Array<CountryItem> }>()
);
export const GetCountryListError = createAction(
    SelfServiceDatabaseSettingsInnerActionTypes.GetCountryListError,
    props<{ payload: BasicResponse }>()
);
