import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { PaymentCredentialRes, PaymentGatewayAccountRes } from './payment-gateways.interface';

export enum SelfServicePaymentGatewaysHttpActionTypes {
    LoadPaymentGatewaysData = '[SelfServicePaymentGateways] Load Payment Gateways Data',
    CreatePaymentGateway = '[SelfServicePaymentGateways] Create Payment Gateway',
    DeletePaymentGateway = '[SelfServicePaymentGateways] Delete Payment Gateway',
    ContinueOnboarding = '[SelfServicePaymentGateways] Continue Onboarding',
    OpenReport = '[SelfServicePaymentGateways] Open Report',
    UpdatePaymentCredentials = '[SelfServicePaymentGateways] Update Payment Credentials',
    GetPaymentCredential = '[SelfServicePaymentGateways] Get Payment Credential',
    ClearPaymentCredentials = '[SelfServicePaymentGateways] Clear Payment Credentials',
}

export enum SelfServicePaymentGatewaysInnerActionTypes {
    LoadPaymentGatewaysDataSuccess = '[SelfServicePaymentGateways] Load Payment Gateways Data Success',
    LoadPaymentGatewaysDataDataError = '[SelfServicePaymentGateways] Load Payment Gateways Data Error',
    SetAccountLoadingFalse = '[SelfServicePaymentGateways] Set Account Loading False',
    UpdatePaymentCredentialsSuccess = '[SelfServicePaymentGateways] Update Payment Credentials Success',
    UpdatePaymentCredentialsError = '[SelfServicePaymentGateways] Update Payment Credentials Error',
    GetPaymentCredentialSuccess = '[SelfServicePaymentGateways] Get Payment Credential Success',
    GetPaymentCredentialError = '[SelfServicePaymentGateways] Get Payment Credential Error',
    CreatePaymentGatewaySuccess = '[SelfServicePaymentGateways] Create Payment Gateway Success',
    CreatePaymentGatewayError = '[SelfServicePaymentGateways] Create Payment Gateway Error',
}

// ===== HTTP =====
export const LoadPaymentGatewaysData = createAction(
    SelfServicePaymentGatewaysHttpActionTypes.LoadPaymentGatewaysData,
    props<{ payload: { searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number } }>()
);

export const CreatePaymentGateway = createAction(
    SelfServicePaymentGatewaysHttpActionTypes.CreatePaymentGateway,
    props<{ payload: {productId?: number}, modalId?: string }>()
);

export const DeletePaymentGateway = createAction(
    SelfServicePaymentGatewaysHttpActionTypes.DeletePaymentGateway,
    props<{ payload: { accountId: string } }>()
);

export const ContinueOnboarding = createAction(
    SelfServicePaymentGatewaysHttpActionTypes.ContinueOnboarding,
    props<{ payload: { accountId: string } }>()
);

export const OpenReport = createAction(
    SelfServicePaymentGatewaysHttpActionTypes.OpenReport,
    props<{ payload: { accountId: string } }>()
);

export const UpdatePaymentCredentials = createAction(
    SelfServicePaymentGatewaysHttpActionTypes.UpdatePaymentCredentials,
    props<{ payload: { productId: number }, accountId?: string, paymentCredentialsId?: number, modalId?: string }>()
);

export const GetPaymentCredential = createAction(
    SelfServicePaymentGatewaysHttpActionTypes.GetPaymentCredential,
    props<{ payload: { connectedAccountId: string }, modalId?: string }>()
);

// ====== INNER ======
export const LoadPaymentGatewaysDataSuccess = createAction(
    SelfServicePaymentGatewaysInnerActionTypes.LoadPaymentGatewaysDataSuccess,
    props<{ payload: ResponseWrapper<PaymentGatewayAccountRes> }>()
);

export const LoadPaymentGatewaysDataError = createAction(
    SelfServicePaymentGatewaysInnerActionTypes.LoadPaymentGatewaysDataDataError,
    props<{ payload: BasicResponse }>()
);

export const SetAccountLoadingFalse = createAction(
    SelfServicePaymentGatewaysInnerActionTypes.SetAccountLoadingFalse,
    props<{ payload: { accountId: string } }>()
);

export const UpdatePaymentCredentialsSuccess = createAction(
    SelfServicePaymentGatewaysInnerActionTypes.UpdatePaymentCredentialsSuccess
);

export const UpdatePaymentCredentialsError = createAction(
    SelfServicePaymentGatewaysInnerActionTypes.UpdatePaymentCredentialsError,
    props<{ payload: BasicResponse }>()
);

export const GetPaymentCredentialSuccess = createAction(
    SelfServicePaymentGatewaysInnerActionTypes.GetPaymentCredentialSuccess,
    props<{ payload: PaymentCredentialRes}>()
);

export const GetPaymentCredentialError = createAction(
    SelfServicePaymentGatewaysInnerActionTypes.GetPaymentCredentialError,
    props<{ payload: BasicResponse }>()
);

export const CreatePaymentGatewaySuccess = createAction(
    SelfServicePaymentGatewaysInnerActionTypes.CreatePaymentGatewaySuccess
);

export const CreatePaymentGatewayError = createAction(
    SelfServicePaymentGatewaysInnerActionTypes.CreatePaymentGatewayError,
    props<{ payload: BasicResponse }>()
);

export const ClearPaymentCredentials = createAction(
    SelfServicePaymentGatewaysHttpActionTypes.ClearPaymentCredentials
);
