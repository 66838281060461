import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { AlternateId, AlternateIdReqPayload, AlternateIdsRes } from './alternate-ids.interface';

export enum SelfServiceAlternateIdsHttpActionTypes {
    LoadAlternateIdsData = '[SelfServiceAlternateIds] Load Alternate Ids Data',
    DownloadAlternateIds = '[SelfServiceAlternateIds] Download Alternate Ids',
    GetSelectedAlternateId = '[SelfServiceAlternateIds] Get Selected Alternate Id',
    SaveAlternateId = '[SelfServiceAlternateIds] Save Alternate Id',
}

export enum SelfServiceAlternateIdsInnerActionTypes {
    LoadAlternateIdsDataSuccess = '[SelfServiceAlternateIds] Load Alternate Ids Data Success',
    LoadAlternateIdsDataDataError = '[SelfServiceAlternateIds] Load Alternate Ids Data Error',
    DownloadAlternateIdsSuccess = '[SelfServiceAlternateIds] Download Alternate Ids Success',
    DownloadAlternateIdsError = '[SelfServiceAlternateIds] Download Alternate Ids Error',
    GetSelectedAlternateIdSuccess = '[SelfServiceAlternateIds] Get Selected Alternate Id Success',
    GetSelectedAlternateIdError = '[SelfServiceAlternateIds] Get Selected Alternate Id Error',
    SaveAlternateIdSuccess = '[SelfServiceAlternateIds] Save Alternate Id Success',
    SaveAlternateIdError = '[SelfServiceAlternateIds] Save Alternate Id Error',
    ClearAlternateIdData = '[SelfServiceAlternateIds] Clear Alternate Id Data'
}

// ===== HTTP =====
export const LoadAlternateIdsData = createAction(
    SelfServiceAlternateIdsHttpActionTypes.LoadAlternateIdsData,
    props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number} }>()
);
export const DownloadAlternateIds = createAction(SelfServiceAlternateIdsHttpActionTypes.DownloadAlternateIds);
export const GetSelectedAlternateId = createAction(SelfServiceAlternateIdsHttpActionTypes.GetSelectedAlternateId, props<{ payload: {alternateId: number} }>());
export const SaveAlternateId = createAction(SelfServiceAlternateIdsHttpActionTypes.SaveAlternateId, props<{ payload: AlternateIdReqPayload }>());
// ====== INNER ======
export const LoadAlternateIdsDataSuccess = createAction(
    SelfServiceAlternateIdsInnerActionTypes.LoadAlternateIdsDataSuccess,
    props<{ payload: ResponseWrapper<AlternateIdsRes> }>()
);
export const LoadAlternateIdsDataError = createAction(
    SelfServiceAlternateIdsInnerActionTypes.LoadAlternateIdsDataDataError,
    props<{ payload: BasicResponse }>()
);
export const DownloadAlternateIdsSuccess = createAction(SelfServiceAlternateIdsInnerActionTypes.DownloadAlternateIdsSuccess);
export const DownloadAlternateIdsError = createAction(SelfServiceAlternateIdsInnerActionTypes.DownloadAlternateIdsError, props<{ payload: BasicResponse }>());
export const GetSelectedAlternateIdSuccess = createAction(SelfServiceAlternateIdsInnerActionTypes.GetSelectedAlternateIdSuccess, props<{ payload: ResponseWrapper<AlternateId> }>());
export const GetSelectedAlternateIdError = createAction(SelfServiceAlternateIdsInnerActionTypes.GetSelectedAlternateIdError, props<{ payload: BasicResponse }>());
export const SaveAlternateIdSuccess = createAction(SelfServiceAlternateIdsInnerActionTypes.SaveAlternateIdSuccess, props<{ payload: BasicResponse }>());
export const SaveAlternateIdError = createAction(SelfServiceAlternateIdsInnerActionTypes.SaveAlternateIdError, props<{ payload: BasicResponse }>());
export const ClearAlternateIdData = createAction(SelfServiceAlternateIdsInnerActionTypes.ClearAlternateIdData);
