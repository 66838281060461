import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { DemographicsListRes, SaveCalculatedDemographicPayload, SelectedDemographicRes, SourceDemographicsRes } from './calculated-demographics.interface';

export enum SelfServiceCalculatedDemographicsHttpActionTypes {
    LoadCalculatedDemographicsData = '[SelfServiceCalculatedDemographics] Load Calculated Demographics Data',
    DownloadCalculatedDemographicsList = '[SelfServiceCalculatedDemographics] Download Calculated Demographics List',
    GetCalculatedDemographicData = '[SelfServiceCalculatedDemographics] Get Calculated Demographic Data',
    GetSourceDemographics = '[SelfServiceCalculatedDemographics] Get Source Demographics',
    SaveCalculatedDemographic = '[SelfServiceCalculatedDemographics] Save Calculated Demographic',
}

export enum SelfServiceCalculatedDemographicsInnerActionTypes {
    LoadCalculatedDemographicsDataSuccess = '[SelfServiceCalculatedDemographics] Load Calculated Demographics Data Success',
    LoadCalculatedDemographicsDataError = '[SelfServiceCalculatedDemographics] Load Calculated Demographics Data Error',
    DownloadCalculatedDemographicsListSuccess = '[SelfServiceCalculatedDemographics] Download Calculated Demographics List Success',
    DownloadCalculatedDemographicsListError = '[SelfServiceCalculatedDemographics] Download Calculated Demographics List Error',
    GetCalculatedDemographicDataSuccess = '[SelfServiceCalculatedDemographics] Get Calculated Demographic Data Success',
    GetCalculatedDemographicDataError = '[SelfServiceCalculatedDemographics] Get Calculated Demographic Data Error',
    ClearCalculatedDemographicData = '[SelfServiceCalculatedDemographics] Clear Calculated Demographic Data',
    GetSourceDemographicsSuccess = '[SelfServiceCalculatedDemographics] Get Source Demographics Success',
    GetSourceDemographicsError = '[SelfServiceCalculatedDemographics] Get Source Demographics Error',
    SaveCalculatedDemographicSuccess = '[SelfServiceCalculatedDemographics] Save Calculated Demographic Success',
    SaveCalculatedDemographicError = '[SelfServiceCalculatedDemographics] Save Calculated Demographic Error',
}

// ===== HTTP =====
export const LoadCalculatedDemographicsData = createAction(
    SelfServiceCalculatedDemographicsHttpActionTypes.LoadCalculatedDemographicsData,
    props<{ payload: {searchText?: string, sortBy?: string, order?: string, numResults?: number, offset?: number, singlePage?: boolean} }>()
);
export const DownloadCalculatedDemographicsList = createAction(SelfServiceCalculatedDemographicsHttpActionTypes.DownloadCalculatedDemographicsList);
export const GetCalculatedDemographicData = createAction(
    SelfServiceCalculatedDemographicsHttpActionTypes.GetCalculatedDemographicData,
    props<{ payload: {demographicId?: number} }>()
);
export const GetSourceDemographics = createAction(
    SelfServiceCalculatedDemographicsHttpActionTypes.GetSourceDemographics,
    props<{ payload: {demographicType: number, order?: string, numResults?: number, offset?: number, sortBy?: string} }>()
);
export const SaveCalculatedDemographic = createAction(
    SelfServiceCalculatedDemographicsHttpActionTypes.SaveCalculatedDemographic,
    props<{ payload: SaveCalculatedDemographicPayload }>()
);
// ====== INNER ======
export const LoadCalculatedDemographicsDataSuccess = createAction(
    SelfServiceCalculatedDemographicsInnerActionTypes.LoadCalculatedDemographicsDataSuccess,
    props<{ payload: ResponseWrapper<DemographicsListRes> }>()
);
export const LoadCalculatedDemographicsDataError = createAction(
    SelfServiceCalculatedDemographicsInnerActionTypes.LoadCalculatedDemographicsDataError,
    props<{ payload: BasicResponse }>()
);
export const DownloadCalculatedDemographicsListSuccess = createAction(
    SelfServiceCalculatedDemographicsInnerActionTypes.DownloadCalculatedDemographicsListSuccess
);
export const DownloadCalculatedDemographicsListError = createAction(
    SelfServiceCalculatedDemographicsInnerActionTypes.DownloadCalculatedDemographicsListError,
    props<{ payload: BasicResponse }>()
);
export const GetCalculatedDemographicDataSuccess = createAction(
    SelfServiceCalculatedDemographicsInnerActionTypes.GetCalculatedDemographicDataSuccess,
    props<{ payload: ResponseWrapper<SelectedDemographicRes> }>()
);
export const GetCalculatedDemographicDataError = createAction(
    SelfServiceCalculatedDemographicsInnerActionTypes.GetCalculatedDemographicDataError,
    props<{ payload: BasicResponse }>()
);
export const ClearCalculatedDemographicData = createAction(SelfServiceCalculatedDemographicsInnerActionTypes.ClearCalculatedDemographicData);
export const GetSourceDemographicsSuccess = createAction(
    SelfServiceCalculatedDemographicsInnerActionTypes.GetSourceDemographicsSuccess,
    props<{ payload: ResponseWrapper<SourceDemographicsRes> }>()
);
export const GetSourceDemographicsError = createAction(
    SelfServiceCalculatedDemographicsInnerActionTypes.GetSourceDemographicsError,
    props<{ payload: BasicResponse }>()
);
export const SaveCalculatedDemographicSuccess = createAction(
    SelfServiceCalculatedDemographicsInnerActionTypes.SaveCalculatedDemographicSuccess,
    props<{ payload: BasicResponse }>()
);
export const SaveCalculatedDemographicError = createAction(
    SelfServiceCalculatedDemographicsInnerActionTypes.SaveCalculatedDemographicError,
    props<{ payload: BasicResponse }>()
);
